import React, { useState, useEffect } from 'react';
import editBrandStyle from './editbrand.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  DropdownField,
  DropdownMultiField,
  Button,
  CardColumn,
  UploadButton,
  CardImage,
  PdfViewerModal,
  Checkbox,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import BrandService from '../../services/brands/brand.service';
import SampleService from '../../services/samples/sample.service';
import { HelpCircle } from 'react-feather';
import ModalScoreScale from './ModalScoreScale';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../../contexts/User';

const EditBrand = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [families, setFamilies] = useState([]);
  const [products, setProducts] = useState([]);
  const [brand, setBrand] = useState({
    ...props.location.state.values,
  });
  const [checkZone, setCheckZone] = useState(false);
  const [open, setOpen] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [isNewBrand, setIsNewBrand] = useState(true);

  // services
  const brandService = new BrandService();
  const sampleService = new SampleService();
  const { user } = useUser();

  useEffect(() => {
    setIsNewBrand(props.location.state.values.isNew);
    refreshState();
    if (props.location.state.values.isNew) {
      setBrand(prevState => {
        return {
          ...prevState,
          arr_zones: [
            {
              str_name: user.str_name_zone,
              uuid_zone: user.uuid_zone,
            },
          ],
        };
      });
    }
  }, []);

  const refreshState = () => {
    const brandTemp = props.location.state.values;
    const privilegeTemp = props.location.state.privilege;

    setCheckZone(false);
    setPrivilege(privilegeTemp);

    brandService.listFamilies().then(
      res => {
        if (res.type === 'success' && res.results) {
          setFamilies(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    sampleService.listProducts().then(
      res => {
        if (res.type === 'success' && res.results) {
          setProducts(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );

    if (brandTemp.arr_zones) {
      brandTemp.arr_zones.map(brandZone => {
        privilegeTemp.arr_zones?.map(profileZone => {
          if (profileZone.uuid_zone === brandZone.uuid_zone) {
            setCheckZone(true);
          }
        });
      });
    } else {
      setCheckZone(true);
    }
  };

  const handleChange = evt => {
    if (evt.target !== undefined) {
      const value = evt.target.value;
      setBrand({
        ...brand,
        [evt.target.name]: value,
      });
    }
  };

  const modalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleSave = () => {
    if (!brand.uuid_brand_family?.trim()) {
      window.alert(`${t('you_must_input')} '${t('common_family')}'`);
      return false;
    }
    if (brand.arr_zones?.length === 0) {
      window.alert(`${t('you_must_input')} '${t('common_zone')}'`);
      return false;
    }
    if (!brand.uuid_product_type?.trim()) {
      window.alert(`${t('you_must_input')} '${t('common_product_type')}'`);
      return false;
    }
    if (!brand.str_name_en?.trim()) {
      window.alert(`${t('you_must_input')} '${t('common_brand')} (English)'`);
      return false;
    }
    if (
      (!brand.str_name_en?.trim() || !brand.str_img_profile_en?.trim()) &&
      (!brand.str_name_pt?.trim() || !brand.str_img_profile_pt?.trim()) &&
      (!brand.str_name_es?.trim() || !brand.str_img_profile_es?.trim()) &&
      (!brand.str_name_de?.trim() || !brand.str_img_profile_de?.trim()) &&
      (!brand.str_name_fr?.trim() || !brand.str_img_profile_fr?.trim()) &&
      (!brand.str_name_it?.trim() || !brand.str_img_profile_it?.trim()) &&
      (!brand.str_name_nl?.trim() || !brand.str_img_profile_nl?.trim()) &&
      (!brand.str_name_ru?.trim() || !brand.str_img_profile_ru?.trim()) &&
      (!brand.str_name_uk?.trim() || !brand.str_img_profile_uk?.trim()) &&
      (!brand.str_name_zh?.trim() || !brand.str_img_profile_zh?.trim())
    ) {
      window.alert(t('brand_has_no_english_name'));
      return false;
    }

    if (!brand.uuid_product_type?.trim()) {
      window.alert(t('brand_has_no_product_type'));
      return false;
    }

    let brandTemp = { ...brand };
    brandTemp.arr_zones = brandTemp.arr_zones?.map(zoneTemp => {
      return zoneTemp.uuid_zone;
    });
    brandTemp.arr_countries = brandTemp.arr_countries?.map(countryTemp => {
      return countryTemp.uuid_country;
    });
    brandTemp.arr_sites = brandTemp.arr_sites?.map(siteTemp => {
      return siteTemp.uuid_site;
    });

    brandService.updateBrand(brandTemp).then(
      res => {
        if (res.type === 'success' && res.results) {
          if (res.results[0].brand_sp_in_up_brand) {
            window.alert(t('brand_duplicate'));
          }
          history.goBack();
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleClosePdf = () => {
    setOpen('');
  };

  const handlePDF = lng => {
    setOpen(lng);
  };

  return (
    <div className={editBrandStyle.pageContainer}>
      <ModalScoreScale
        isOpen={modalOpen}
        onCloseModal={modalToggle}
        brand={brand}
      />

      <Label label={t('brand_edit_brand')} bold neutral />

      <Card bordered>
        <div className={editBrandStyle.pageContainerHeader}>
          <CardRow flexWrap="wrap" padding="0px 0px 10px 0px">
            <CardColumn padding="5px" flex={1}>
              <CardRow flexWrap="wrap">
                {brand.str_img_brand ? (
                  brand.str_img_brand.split('.').pop() === 'pdf' ? (
                    <PdfViewerModal
                      label={`${t('common_brand')} Logo PDF`}
                      data={brand.str_img_brand}
                      onClick={() => handlePDF(brand.str_img_brand)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_brand}
                      loading={false}
                      downloadFileName={brand.str_img_brand}
                      onRemove={() =>
                        handleChange({
                          target: {
                            value: '',
                            name: 'str_img_brand',
                          },
                        })
                      }
                      name={brand.str_img_brand}
                    />
                  ) : (
                    <CardImage
                      name="str_img_brand"
                      src={brand.str_img_brand}
                      onRemove={handleChange}
                      margin="10px 0px"
                      size={150}
                    />
                  )
                ) : (
                  <div className={editBrandStyle.uploadButton}>
                    <UploadButton
                      name="str_img_brand"
                      label={t('brand_upload_brand_logo')}
                      onUpload={handleChange}
                      margin="0px 5px 0px 0px"
                      allowExtension={['.png', '.jpg', '.jpeg']}
                      maxSize={5242880}
                    />
                    <HelpCircle
                      data-tip={t('brand_upload_help_message')}
                      className={editBrandStyle.helpCircle}
                    />
                    <ReactTooltip
                      effect="solid"
                      place="top"
                      type="warning"
                      className={editBrandStyle.tooltip}
                    />
                  </div>
                )}
              </CardRow>
            </CardColumn>
            <CardColumn padding="5px" flex={3}>
              <CardRow flexWrap="wrap">
                <DropdownField
                  name="uuid_brand_family"
                  options={families}
                  valueField="uuid_brand_family"
                  labelField="str_name"
                  label={t('common_family')}
                  margin="10px 0px 0px 0px"
                  value={brand.uuid_brand_family}
                  onChange={handleChange}
                />
              </CardRow>
              <CardRow>
                <DropdownMultiField
                  name="arr_zones"
                  options={privilege.arr_zones ? privilege.arr_zones : []}
                  valueField="uuid_zone"
                  labelField="str_name"
                  label={t('common_zone')}
                  margin="10px 0px 0px 0px"
                  value={brand.arr_zones}
                  disabled={!checkZone}
                  onChange={value => {
                    brand.arr_countries = null;
                    brand.arr_sites = null;
                    handleChange(value);
                  }}
                />
              </CardRow>
              <CardRow>
                <DropdownField
                  name="uuid_product_type"
                  options={products}
                  valueField="uuid_product_type"
                  labelField="str_name_product"
                  label={t('common_product_type')}
                  margin="10px 0px 0px 0px"
                  value={brand.uuid_product_type}
                  onChange={handleChange}
                />
              </CardRow>
              <CardRow itemAlign={'flex-end'}>
                <Checkbox
                  label={t('competitor_beer')}
                  name={'bol_competitor_beer'}
                  value={brand.bol_competitor_beer}
                  margin="10px 0 0 10px"
                  padding="8px 10px 8px 30px"
                  onChange={evt => {
                    handleChange(evt);
                  }}
                />
              </CardRow>
            </CardColumn>
          </CardRow>
        </div>

        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_en"
                label={`${t('common_brand')} (English)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_en}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_en ? (
                brand?.str_img_profile_en.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_en}
                      onClick={() => handlePDF(brand.str_img_profile_en)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_en}
                      loading={false}
                      downloadFileName={brand.str_img_profile_en}
                      onRemove={handleChange}
                      name="str_img_profile_en"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_en"
                    src={brand.str_img_profile_en}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_en"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_pt"
                label={`${t('common_brand')} (Portuguese)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_pt}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_pt ? (
                brand?.str_img_profile_pt.split('.').pop() === 'pdf' ? (
                  <div className={editBrandStyle.ptContainer}>
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_pt}
                      onClick={() => handlePDF(brand.str_img_profile_pt)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_pt}
                      loading={false}
                      downloadFileName={brand.str_img_profile_pt}
                      onRemove={handleChange}
                      name="str_img_profile_pt"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_pt"
                    src={brand.str_img_profile_pt}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_pt"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_es"
                label={`${t('common_brand')} (Spanish)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_es}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_es ? (
                brand?.str_img_profile_es.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_es}
                      onClick={() => handlePDF(brand.str_img_profile_es)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_es}
                      loading={false}
                      downloadFileName={brand.str_img_profile_es}
                      onRemove={handleChange}
                      name="str_img_profile_es"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_es"
                    src={brand.str_img_profile_es}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_es"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
        </CardRow>

        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_de"
                label={`${t('common_brand')} (German)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_de}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_de ? (
                brand?.str_img_profile_de.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_de}
                      onClick={() => handlePDF(brand.str_img_profile_de)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_de}
                      loading={false}
                      downloadFileName={brand.str_img_profile_de}
                      onRemove={handleChange}
                      name="str_img_profile_de"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_de"
                    src={brand.str_img_profile_de}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_de"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_fr"
                label={`${t('common_brand')} (French)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_fr}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_fr ? (
                brand?.str_img_profile_fr.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_fr}
                      onClick={() => handlePDF(brand.str_img_profile_fr)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_fr}
                      loading={false}
                      downloadFileName={brand.str_img_profile_fr}
                      onRemove={handleChange}
                      name="str_img_profile_fr"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_fr"
                    src={brand.str_img_profile_fr}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_fr"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_it"
                label={`${t('common_brand')} (Italian)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_it}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_it ? (
                brand?.str_img_profile_it.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_it}
                      onClick={() => handlePDF(brand.str_img_profile_it)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_it}
                      loading={false}
                      downloadFileName={brand.str_img_profile_it}
                      onRemove={handleChange}
                      name="str_img_profile_it"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_it"
                    src={brand.str_img_profile_it}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_it"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
        </CardRow>

        <CardRow flexWrap="wrap">
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_nl"
                label={`${t('common_brand')} (Dutch)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_nl}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_nl ? (
                brand?.str_img_profile_nl.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_nl}
                      onClick={() => handlePDF(brand.str_img_profile_nl)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_nl}
                      loading={false}
                      downloadFileName={brand.str_img_profile_nl}
                      onRemove={handleChange}
                      name="str_img_profile_nl"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_nl"
                    src={brand.str_img_profile_nl}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_nl"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_ru"
                label={`${t('common_brand')} (Russian)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_ru}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_ru ? (
                brand?.str_img_profile_ru.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_ru}
                      onClick={() => handlePDF(brand.str_img_profile_ru)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_ru}
                      loading={false}
                      downloadFileName={brand.str_img_profile_ru}
                      onRemove={handleChange}
                      name="str_img_profile_ru"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_ru"
                    src={brand.str_img_profile_ru}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_ru"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_uk"
                label={`${t('common_brand')} (Ukranian)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_uk}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_uk ? (
                brand?.str_img_profile_uk.split('.').pop() === 'pdf' ? (
                  <div
                    style={{
                      width: '220px',
                      marginTop: '10px',
                    }}
                  >
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_uk}
                      onClick={() => handlePDF(brand.str_img_profile_uk)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_uk}
                      loading={false}
                      downloadFileName={brand.str_img_profile_uk}
                      onRemove={handleChange}
                      name="str_img_profile_uk"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_uk"
                    src={brand.str_img_profile_uk}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_uk"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
        </CardRow>
        <CardRow>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_zh"
                label={`${t('common_brand')} (Chinese)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_zh}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_zh ? (
                brand?.str_img_profile_zh.split('.').pop() === 'pdf' ? (
                  <div className={editBrandStyle.zhModal}>
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_zh}
                      onClick={() => handlePDF(brand.str_img_profile_zh)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_zh}
                      loading={false}
                      downloadFileName={brand.str_img_profile_zh}
                      onRemove={handleChange}
                      name="str_img_profile_zh"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_zh"
                    src={brand.str_img_profile_zh}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_zh"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_kr"
                label={`${t('common_brand')} (Korean)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_kr}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_kr ? (
                brand?.str_img_profile_kr.split('.').pop() === 'pdf' ? (
                  <div className={editBrandStyle.zhModal}>
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_kr}
                      onClick={() => handlePDF(brand.str_img_profile_kr)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_kr}
                      loading={false}
                      downloadFileName={brand.str_img_profile_kr}
                      onRemove={handleChange}
                      name="str_img_profile_kr"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_kr"
                    src={brand.str_img_profile_kr}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_kr"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>
          <CardColumn padding="10px">
            <CardRow flexWrap="wrap">
              <TextField
                name="str_name_cz"
                label={`${t('common_brand')} (Czech)`}
                margin="10px 10px 0px 0px"
                value={brand.str_name_cz}
                onChange={handleChange}
              />
            </CardRow>
            <CardRow flexWrap="wrap">
              {brand.str_img_profile_cz ? (
                brand?.str_img_profile_cz.split('.').pop() === 'pdf' ? (
                  <div className={editBrandStyle.zhModal}>
                    <PdfViewerModal
                      label={`${t('common_brand')} PDF`}
                      data={brand.str_img_profile_cz}
                      onClick={() => handlePDF(brand.str_img_profile_cz)}
                      closeForm={handleClosePdf}
                      open={open === brand.str_img_profile_cz}
                      loading={false}
                      downloadFileName={brand.str_img_profile_cz}
                      onRemove={handleChange}
                      name="str_img_profile_cz"
                    />
                  </div>
                ) : (
                  <CardImage
                    name="str_img_profile_cz"
                    src={brand.str_img_profile_cz}
                    onRemove={handleChange}
                    margin="10px 0px"
                  />
                )
              ) : (
                <UploadButton
                  name="str_img_profile_cz"
                  label={t('brand_profile_picture')}
                  onUpload={handleChange}
                  margin="10px 10px 0px 0px"
                  allowExtension={['.png', '.jpg', '.jpeg', '.pdf']}
                  maxSize={5242880}
                />
              )}
            </CardRow>
          </CardColumn>


        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="35px 10px 0px 0px"
            zIndex={0}
          />
          {checkZone ? (
            <Button
              label={t('common_save')}
              onClick={handleSave}
              confirm
              margin="35px 10px 0px 0px"
              zIndex={0}
            />
          ) : (
            <div></div>
          )}
        </CardRow>
      </Card>
    </div>
  );
};

export default EditBrand;

