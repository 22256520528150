import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import listProcessCheckStyle from './listProcessCheck.module.scss';
import {
  GenericTable,
  Label,
  CustomColumns,
  CardRow,
  CardColumn,
  Button,
  TextField,
  TextArea,
  DatePickerField,
} from '../../../components';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';
import { useTranslation } from 'react-i18next';
import UserService from '../../services/users/user.service';
import RoundService from '../../services/rounds/round.service';

const ListProcessCheck = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  const appService = new AppService();
  const userService = new UserService();
  const roundService = new RoundService();
  const reportService = new ReportService();
  const [showCustomColumns, setShowCustomColumns] = useState(false);
  const [editPermission, setEditPermission] = useState([
    { str_name: 'common_id', bol_status: null },
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'common_country', bol_status: null },
    { str_name: 'common_site', bol_status: null },
    { str_name: 'common_department', bol_status: null },
    { str_name: 'common_taster', bol_status: null },
    { str_name: 'common_category_sample_name', bol_status: null },
    { str_name: 'common_sample_date', bol_status: null },
    { str_name: 'common_oknok', bol_status: null },
    { str_name: 'common_tank', bol_status: null },
  ]);
  const [showDetail, setShowDetail] = useState(false);
  const [processCheckDataDetails, setProcessCheckDataDetails] = useState([]);
  const [processCheckData, setProcessCheckData] = useState([]);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('PROCESS_CHECK').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    userService.listCustomColumns('processCheck').then(res => {
      if (
        res.type === 'success' &&
        res.results[0].user_sp_se_column_options?.length ===
        editPermission.length
      ) {
        setEditPermission(res.results[0].user_sp_se_column_options);
      }
    });
  };
  const handleNewProcess = useCallback(() => {
    history.push({
      pathname: '/edit_process_check',
      state: {
        values: { uuid_sample: uuid() },
        privilege: privilege,
        isNewProcessCheck: true,
      },
    });
  }, [privilege]);

  const handleCopySample = useCallback(
    values => {
      roundService.getProcessCheckDetail(values.uuid_round).then(res => {
        if (res.type === 'success' && res.results) {
          history.push({
            pathname: '/edit_process_check',
            state: {
              values: {
                ...values,
                str_remark: res.results[0].str_remark,
                str_tank: res.results[0].str_tank,
                uuid_shift: res.results[0].uuid_shift,
                uuid_brand: res.results[0].uuid_brand,
                uuid_package: res.results[0].uuid_package,
              },
              privilege: privilege,
              isNewSample: true,
              isCopySample: true,
            },
          });
        } else {
          window.alert(res.message);
        }
      });
    },
    [privilege],
  );

  const handleRemoveSample = values => {
    return new Promise((resolve, reject) => {
      if (
        window.confirm(
          `${t('remove_process_check')}: ${values.str_name_site} - ${values.str_name_sample
          }`,
        )
      ) {
        roundService.delProcessCheck(values.uuid_round).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const handleViewSample = useCallback(
    values => {
      setShowDetail(true);
      setProcessCheckDataDetails(values);
      return new Promise((resolve, reject) => {
        roundService.getProcessCheckDetail(values.uuid_round).then(
          res => {
            if (res.type === 'success' && res.results) {
              setProcessCheckData(res.results[0]);
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      });
    },
    [privilege],
  );

  const filterHeaders = [
    {
      description: t('common_id'),
      field: 'int_round',
      cache: true,
      fieldCache: 'sample.int_round',
    },
    {
      description: t('common_zone'),
      field: 'str_name_zone',
      cache: true,
      fieldCache: 'sample.str_name_zone',
    },
    {
      description: t('common_country'),
      field: 'str_name_country',
      cache: true,
      fieldCache: 'sample.str_name_country',
    },
    {
      description: t('common_site'),
      field: 'str_name_site',
      cache: true,
      fieldCache: 'sample.str_name_site',
    },
    {
      description: t('common_department'),
      field: 'str_name_department',
      cache: true,
      fieldCache: 'sample.str_name_department',
    },
    {
      description: t('common_taster'),
      field: 'str_name_user',
      cache: true,
      fieldCache: 'sample.str_name_user',
    },
    {
      description: t('common_category_sample_name'),
      field: 'str_name_sample',
      cache: true,
      fieldCache: 'sample.str_name_sample',
    },
    {
      description: t('common_sample_date'),
      field: 'dat_sample_date',
      type: 'datetime',
      cache: true,
      notSearchable: true,
      fieldCache: 'sample.dat_sample_date',
    },
    {
      description: t('common_oknok'),
      field: 'str_ok_nok',
      cache: true,
      fieldCache: 'sample.str_ok_nok',
    },
    {
      description: t('common_tank'),
      field: 'str_tank',
      cache: true,
      fieldCache: 'sample.str_tank',
    },
  ];

  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });

  return (
    <div
      className={listProcessCheckStyle.pageContainer + ' list_process_check'}
    >
      <Label label={t('process_check')} bold neutral />
      {showCustomColumns && (
        <CustomColumns
          title={t('column_options')}
          round={'processCheck'}
          setShow={setShowCustomColumns}
          onChange={setEditPermission}
          explain={t('select_which_of_the_columns_do_you_want_to_be_displayed')}
          values={editPermission}
          arrHeader={headers}
        />
      )}
      <GenericTable
        setShowCustomColumns={setShowCustomColumns}
        arrHeader={headers}
        originHeader={filterHeaders}
        dataServer={roundService.getProcessCheckPagination}
        exportService={reportService.downloadProcessCheck}
        searchable={true}
        onRemove={privilege.bol_access ? handleRemoveSample : null}
        onCopy={privilege.bol_access ? handleCopySample : null}
        onView={privilege.bol_access ? handleViewSample : null}
        newRegister={
          privilege.bol_access
            ? {
              onClick: handleNewProcess,
              label: t('new_process_check'),
            }
            : null
        }
        loading={loading}
        exportLabel={t('round_report')}
        customRanges={[3, 7, 14, 30]}
        defaultRangesDays={7}
        rangesHelpCircle={t(
          'sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration',
        )}
      />
      {showDetail && (
        <div className={listProcessCheckStyle.showListModal}>
          <div className={listProcessCheckStyle.showListBox}>
            <div className={listProcessCheckStyle.closeButton}>
              <CardRow>
                <Button
                  label="x"
                  round
                  style={{
                    display: 'flex',
                    width: '30px',
                    fontSize: '18px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '5px',
                  }}
                  onClick={evt => setShowDetail(false)}
                />
              </CardRow>
            </div>
            <CardRow style={{ flexWrap: 'wrap' }}>
              <CardColumn>
                <TextField
                  name="uuid_zone"
                  label={t('common_zone')}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckDataDetails.str_name_zone
                      ? processCheckDataDetails.str_name_zone
                      : ''
                  }
                  disabled
                />
              </CardColumn>
              <CardColumn>
                <TextField
                  name="uuid_country"
                  label={`${t('common_country')}`}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckDataDetails.str_name_country
                      ? processCheckDataDetails.str_name_country
                      : ''
                  }
                  disabled
                />
              </CardColumn>
              <CardColumn>
                <TextField
                  name="uuid_site"
                  label={`${t('common_site')}`}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckDataDetails.str_name_site
                      ? processCheckDataDetails.str_name_site
                      : ''
                  }
                  disabled
                />
              </CardColumn>
            </CardRow>
            <CardRow style={{ flexWrap: 'wrap' }}>
              <CardColumn>
                <TextField
                  name="uuid_department"
                  label={`${t('common_department')}`}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckDataDetails.str_name_department
                      ? processCheckDataDetails.str_name_department
                      : ''
                  }
                  disabled
                />
              </CardColumn>
              <CardColumn>
                <TextField
                  name="uuid_sample"
                  label={`${t('common_sample')}`}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckDataDetails.str_name_sample
                      ? processCheckDataDetails.str_name_sample
                      : ''
                  }
                  disabled
                />
              </CardColumn>
              {(processCheckDataDetails.uuid_department ===
                'f1e83a3c-1306-11ee-a478-0017fa02c1e1' ||
                processCheckDataDetails.uuid_sample ===
                '5f5b20ba-2eb3-11eb-ba19-0229531a6f14') && (
                  <CardColumn>
                    <TextField
                      name="uuid_brand"
                      label={`${t('common_brand')}`}
                      margin="5px 10px 0px 0px"
                      style={{ height: '40px' }}
                      value={
                        processCheckData.str_name_brand
                          ? processCheckData.str_name_brand
                          : ''
                      }
                      disabled
                    />
                  </CardColumn>
                )}
            </CardRow>
            <CardRow style={{ flexWrap: 'wrap' }}>
              {processCheckDataDetails.uuid_department ===
                'f1e83a3c-1306-11ee-a478-0017fa02c1e1' && (
                  <CardColumn>
                    <TextField
                      name="uuid_package"
                      label={`${t('sample_package')}`}
                      margin="5px 10px 0px 0px"
                      style={{ height: '40px' }}
                      value={
                        processCheckData.str_name_package
                          ? processCheckData.str_name_package
                          : ''
                      }
                      disabled
                    />
                  </CardColumn>
                )}
              <CardColumn>
                <TextField
                  name="uuid_shift"
                  label={`${t('common_shift')}`}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckData.str_name_shift
                      ? processCheckData.str_name_shift
                      : ''
                  }
                  disabled
                />
              </CardColumn>
              <CardColumn>
                <DatePickerField
                  name="dat_sample_date"
                  type={'datetime-local'}
                  label={`${t('common_sample_date')}`}
                  step={1}
                  margin="5px 10px 0px 0px"
                  padding="8px 4px 8px 5px"
                  height="40px"
                  value={
                    processCheckDataDetails.dat_sample_date
                      ? processCheckDataDetails.dat_sample_date.substring(0, 19)
                      : ''
                  }
                  disabled
                />
              </CardColumn>
            </CardRow>
            <CardRow style={{ flexWrap: 'wrap' }}>
              {processCheckDataDetails.uuid_department ===
                'f1e83a3c-1306-11ee-a478-0017fa02c1e1' && (
                  <CardColumn>
                    <TextField
                      name="uuid_production_line"
                      label={`${t('common_production_line')}`}
                      margin="5px 10px 0px 0px"
                      style={{ height: '40px' }}
                      value={
                        processCheckData.str_name_production_line
                          ? processCheckData.str_name_production_line
                          : ''
                      }
                      disabled
                    />
                  </CardColumn>
                )}
              <CardColumn>
                <TextField
                  name="str_ok_nok"
                  label={`${t('OKNOK')}`}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckDataDetails.str_ok_nok
                      ? processCheckDataDetails.str_ok_nok
                      : ''
                  }
                  disabled
                />
              </CardColumn>
              <CardColumn>
                <TextField
                  name="str_tank"
                  label={t('common_tank')}
                  margin="5px 10px 0px 0px"
                  style={{ height: '40px' }}
                  value={
                    processCheckData.str_tank ? processCheckData.str_tank : ''
                  }
                  disabled
                />
              </CardColumn>
            </CardRow>
            <CardRow style={{ flexWrap: 'wrap' }}>
              <TextArea
                name="str_remark"
                label={t('common_remark')}
                margin="15px 20px 10px 10px"
                value={
                  processCheckData.str_remark !== undefined
                    ? processCheckData.str_remark
                    : ''
                }
                disabled
                maxLength={300}
              />
            </CardRow>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListProcessCheck;
