export default {
  sample_no_production_date_message: 'Herstellungsdatum muss ausgewählt werden',
  round_list_rounds: 'Runden',
  round_edit_round: 'Runde bearbeiten',
  round_new_round: 'Neue Runde',
  round_remove_round: 'Möchten Sie die Runde wirklich löschen',
  round_schedule: 'Zeitlicher Ablauf',
  round_tasters_unselect_all: 'Alle Verkoster abwählen',
  round_tasters_select_all: 'Alle Verkoster auswählen',
  round_close: 'Runde schließen',
  round_close_message: 'Sind Sie sicher, dass Sie die Runde beenden wollen',
  round_create_site_message:
    'Sie erstellen eine Runde, die nicht aus Ihrer Brauerei  stammt!',
  round_closed_message: 'Geschlossene Runden können nicht bearbeitet werden',
  round_status_created: 'Erstellt',
  round_status_samples: 'Proben',
  round_status_scheduled: 'Geplant',
  round_status_tasters: 'Verkoster',
  round_status_in_progress: 'Im Gange',
  round_status_finished: 'Fertig',
  round_status_closed: 'Abgeschlossen',
  round_schedule_start_finish_date:
    'Das Startdatum kann nicht größer als das Enddatum sein!',
  round_schedule_finish_date_no_start_date:
    'Sie müssen zuerst ein Startdatum auswählen!',
  round_disabled_discussion_tooltip:
    'Sie können nicht zu einer Diskussion einer unvollendeten Runde gehen',
  round_delete_other_users_rounds:
    ' Du bist nicht berechtigt, eine Runde zu löschen, die nicht von dir erstellt wurde',
  round_randomize_samples_order_button: 'Mischen',
  round_block_results_tooltip:
    'Sperren Sie das Herunterladen der Ergebnisse dieser Runde im Berichtsbildschirm. Wenn die Schaltfläche deaktiviert ist',
  round_block_results_confirm:
    'Sind Sie sicher, dass Sie die Ergebnisse der Runde sperren/entsperren möchten?',
  round_block_results_no_privilege:
    ' Sie haben nicht die Berechtigung, die Ergebnisse von Runden von diesem Ort aus zu sperren/entsperren.',
  sample_list_samples: 'Proben',
  sample_edit_sample: 'Probe bearbeiten',
  sample_new_sample: 'Neue Probe',
  sample_remove_sample: 'Probe entfernen',
  sample_upload_document: 'Dokumente hochladen',
  sample_download_documents: 'Beispieldokumente',
  sample_tasted_dates: 'Geschmacksdatum',
  sample_upload_message: 'Mehrere Dokumente hochladen',
  sample_not_for_kpi: 'Nicht für KPI',
  sample_package: 'Paket',
  sample_volume: 'Volumen',
  sample_production_date: 'Produktionsdatum',
  sample_expire_date: 'Haltbarkeitsdatum',
  sample_trial_identifier: 'Testkennung',
  sample_project_name: 'Projektname',
  sample_linked: 'Verbunden mit einer Runde',
  sample_expire_bigger_than_production:
    'Das Produktionsdatum kann nicht größer als das Ablaufdatum sein!',
  sample_no_site_message: 'Es ist obligatorisch, eine Zone auszuwählen',
  sample_no_brand_message: 'Es ist obligatorisch, eine Marke auszuwählen.',
  sample_duplicated_kpi_message: 'Es gibt bereits einen KPI für den Monat',
  sample_duplicated_project_message:
    'Es gibt bereits ein Muster mit diesem Projektnamen und dieser Testkennung für diese Brauerei  und Marke',
  sample_project_no_name_or_trial:
    'Projektname und Testkennung sind obligatorisch, wenn Sie ein Muster des Projekttyps erstellen',
  sample_production_to_project_confirm_message:
    'Sie ändern den Typ dieses Beispiels von PRODUKTION in PROJEKT. Möchten Sie fortfahren?',
  sample_project_to_production_confirm_message:
    'Sie ändern den Typ dieses Beispiels von PROJEKT zu PRODUKTION. Möchten Sie fortfahren?',
  sample_no_volume_message:
    'Paket und Volumen sind beim Erstellen/Bearbeiten eines Samples obligatorisch.',
  sample_id_code_message: 'Bitte die vollständige Chargennummer angeben',
  sample_show_all_samples_hint:
    'Anzeigen von Mustern, die bis zu sechs Monate ab heute erstellt wurden. Aktivieren Sie dieses Kontrollkästchen, wenn Sie auch ältere Beispiele sehen möchten',
  sample_show_all_samples_label: 'Alle Beispiele anzeigen',
  package_duplicate: 'Es gibt bereits ein Paket mit diesem Namen',
  common_production: 'Produktion',
  common_project: 'Projekt',
  group_list_groups: 'Gruppen',
  group_edit_group: 'Gruppe bearbeiten',
  group_new_group: 'Neue Gruppe',
  group_remove_group: 'Gruppe entfernen',
  group_close_modal: 'Nah dran',
  group_duplicated_user:
    'Dieser Benutzer wurde bereits zu dieser Gruppe hinzugefügt!',
  group_no_name_message: 'Es ist notwendig, einen Gruppennamen einzugeben',
  group_no_zone_message: 'Es ist notwendig, eine Zone auszuwählen',
  group_no_users_message:
    'Es ist notwendig, mindestens einen Verkoster auszuwählen',
  family_list_families: 'Stile',
  family_edit_family: 'Stil bearbeiten',
  family_new_family: 'Neuer Stil',
  family_remove_family: 'Stil entfernen',
  family_has_no_english_name:
    'Achtung: Der Style-Name muss auf Englisch angegeben werden',
  brand_list_brands: 'Marken',
  brand_edit_brand: 'Marke bearbeiten',
  brand_new_brand: 'Neue Marke',
  brand_remove_brand: 'Marke entfernen',
  brand_profile_picture: 'Bierprofilbild hochladen',
  brand_upload_brand_logo: 'Markenlogo hochladen',
  brand_duplicate: 'Achtung: Es gibt ein Bier mit diesem Namen',
  brand_has_no_english_name:
    'Sie müssen einen Markennamen und ein entsprechendes Bierprofil ausfüllen',
  brand_upload_help_message: 'Nur PNG- und JPG-Dateien',
  brand_has_no_product_type: 'Sie müssen einen Produkttyp auswählen',
  package_list_packages: 'Pakete',
  package_edit_package: 'Pakete bearbeiten',
  package_new_package: 'Neues Paket',
  package_remove_package: 'Paket entfernen',
  package_has_no_english_name:
    'Achtung: Der Paketname muss auf Englisch angegeben werden',
  package_volumes: 'Volumen',
  package_new_volume: 'Neuer Band',
  package_remove_volume: 'Lautstärke entfernen',
  degree_list_degrees: 'Intensitäten',
  degree_edit_degree: 'Intensität bearbeiten',
  degree_new_degree: 'Neue Intensität',
  degree_remove_degree: 'Intensität entfernen',
  category_list_categories: 'Kategorien',
  category_edit_category: 'Geschmackskategorie bearbeiten',
  category_new_category: 'Neue Geschmackskategorie',
  category_remove_category: 'Geschmackskategorie entfernen',
  comment_list_comments: 'Attribute',
  comment_edit_comment: 'Geschmacksattribut bearbeiten',
  comment_new_comment: 'Neues Geschmacksattribut',
  comment_remove_comment: 'Geschmacksattribut entfernen',
  user_list_users: 'Benutzer',
  user_edit_user: 'Benutzer bearbeiten',
  user_new_user: 'Neuer ABI-Vertragspartner',
  user_export_users: 'Benutzer exportieren',
  user_list_profiles: 'Profile',
  user_profile_name: 'Profilname',
  user_profile_select: 'Wählen Sie ein Profil',
  user_profile_remove: 'Profil entfernen',
  user_profile_save: 'Profil sichern',
  user_remove_user: 'Benutzer entfernen',
  user_use_terms: 'kzeptierte Nutzungsbedingungen',
  report_list_report: 'Berichte',
  report_end_date: 'Endtermin',
  common_privileges: 'Privilegien',
  common_abi_name: 'ABI-Name',
  common_id: 'ID',
  common_ranked: 'Rang',
  common_week: 'Woche',
  common_weeks: 'Wochen',
  common_days: 'Tage',
  common_category: 'Geschmackskategorie',
  common_category_sample: 'Kategorie',
  common_degree: 'Intensität',
  common_comment: 'Geschmacksattribut',
  common_status: 'Status',
  common_zone: 'Zone',
  common_country: 'Land',
  common_site: 'Brauerei ',
  common_start_date: 'Startdatum',
  common_finish_date: 'Enddatum',
  common_round_type: 'Testtyp',
  common_round: 'Runden',
  common_round_id: 'Runde ID',
  common_tasters: 'Verkoster',
  common_sample_performance: 'Beispielleistung',
  common_taste: 'Geschmack',
  common_key_taster: 'Key-Schnupper',
  common_sample: 'Stichprobe',
  common_samples: 'Proben',
  common_sample_type: 'Beispielstyp',
  common_family: 'Stil',
  common_brand: 'Marke',
  common_product_type: 'Produktart',
  common_temperature: 'Lagerzustand',
  common_storage_times: 'Lagerzeit',
  common_sku: 'Artikelnummer',
  common_batch: 'Identifikationsnummer',
  common_kpi_month: 'KPI-Monat',
  common_logo: 'Logo',
  common_name: 'Name',
  common_notes: 'Anmerkungen',
  common_email: 'Email',
  common_language: 'Sprache',
  common_terms_of_use: 'Nutzungsbedingungen',
  common_new: 'Neu',
  common_profile: 'Profil',
  common_filter: 'Benutzer filtern',
  common_download: 'Herunterladen',
  common_save: 'Speichern',
  common_cancel: 'Abbrechen',
  common_search_users: 'Benutzer suchen',
  common_search_group: 'Gruppe suchen',
  common_search_taster: 'Schnuppersuche',
  common_no_data: 'Keine Daten anzuzeigen',
  common_loading: 'Wird geladen',
  common_cannot_be_empty: '{{field}} darf nicht leer sein',
  common_select_option: 'Wähle eine Option...',
  common_per_page: 'Zeilen pro Seite',
  common_attributes: 'Attribute',
  common_not_started: 'Nicht angefangen',
  common_in_progress: 'Im Gange',
  common_finished: 'Fertig',
  common_start: 'Start',
  common_detail: 'Einzelheiten',
  common_position: 'Position',
  common_average_key_score: 'Durchschnittliche Schlüsselpunktzahl',
  common_score: 'Punktzahl',
  common_score_scale: 'Bewertungsskala',
  common_intensity: 'Intensität',
  common_report_not_have_data: 'Die ausgewählten Runden hatten keine Stimmen',
  month_0: 'Januar',
  month_1: 'Februar',
  month_2: 'März',
  month_3: 'April',
  month_4: 'Kann',
  month_5: 'Juni',
  month_6: 'Juli',
  month_7: 'August',
  month_8: 'September',
  month_9: 'Oktober',
  month_10: 'November',
  month_11: 'Dezember',
  dropdown_change_picture: 'Bild ändern',
  dropdown_language: 'Sprache',
  dropdown_logout: 'Ausloggen',
  round_not_started: 'Nicht angefangen',
  round_in_progress: 'Im Gange',
  round_finished: 'Fertig',
  round_filter_start: 'Start',
  round_round_type_in_profile: 'Im Profil',
  tasting_list_rounds: 'Runden auflisten',
  menu_tasting_session: 'Verkostungssitzung',
  menu_panel_management: 'Panel-Verwaltung',
  menu_process_management: 'Prozessmanagement',
  menu_product_management: 'Produkt Management',
  menu_attributes_management: 'Attributverwaltung',
  tasting_finish_sample: 'Probe fertigstellen',
  tasting_comments: 'Kommentare',
  tasting_beer_profile: 'Bierprofil',
  tasting_need_select_score_comments:
    'Sie müssen eine Punktzahl festlegen und mindestens einen Kommentar hinzufügen, um fortzufahren...',
  tasting_finish_taste: 'Fertig Geschmack',
  common_no_comments: 'Keine Kommentare',
  review_submit_title:
    'Sind Sie sicher, dass Sie bereit sind, Ihre Ergebnisse zu übermitteln?',
  review_submit_description: 'Sie können später nicht mehr bearbeiten',
  review_finish_taste: 'Fertig Geschmack',
  review_warning_sample_title: 'Sie haben ungeschmeckte Proben!',
  review_warning_sample_description:
    'Möchten Sie fortfahren, ohne sie zu probieren?',
  review_warning_sample_review: 'Rezension',
  review_warning_sample_ok: 'Weitermachen',
  review_warning_tie_sample_title: 'Sie haben Krawattenmuster!',
  review_warning_tie_sample_description:
    'Sie können nicht fortfahren, ohne sie zu bewerten!',
  review_warning_tie_sample_review: 'Rezension',
  common_submit: 'Einreichen',
  common_discussion: 'Diskussion',
  common_not_informed: 'Nicht informiert',
  discussion_order_number: 'Nach Position sortieren',
  discussion_order_brewery: 'Bestellung nach Brauerei',
  discussion_order_ranking: 'Nach Rangfolge sortieren',
  discussion_order_brand: 'Nach Marke bestellen',
  discussion_order_score: 'Nach Punktzahl sortieren',
  discussion_order_key_score: 'Sortieren nach Schlüsselpunktzahl',
  discussion_order_overall_rank: 'Nach Gesamtranglistenergebnis sortieren',
  discussion_order_key_rank: 'Sortieren nach Schlüsselrang',
  discussion_key_ranking: 'Schlüssel-Ranking',
  discussion_export_error: 'Die Datei konnte nicht gespeichert werden!',
  discussion_ranking: 'Rangfolge',
  discussion_overall_ranking: 'Gesamtrangliste',
  common_brewery: 'Brauerei',
  common_trial_identifier: 'ID-Testversion',
  common_key_score: 'Schlüsselpunktzahl',
  round_report: 'Nach Excel exportieren',
  common_comments: 'Kommentare',
  message_you_cannot_delete_all_comments:
    'Du kannst nicht alle Kommentare löschen, wenn du eine Abstimmung bearbeitest',
  common_message_empty_profile: 'Profilname nicht erlaubt leer oder leer',
  common_message_out_score_range: 'außerhalb des Bereichs Score',
  tasting_need_select_score: 'Sie müssen zuerst eine Punktzahl setzen!',
  common_category_name: 'Kategorie',
  categories_new_category: 'Neue Kategorie',
  categories_remove_category: 'Möchten Sie die Kategorie wirklich entfernen?',
  categories_list_categories: 'Kategorien',
  categories_list_all_categories: 'Alle Kategorien',
  common_category_sample_name: 'Stichprobe',
  categories_new_category_sample: 'Neues Muster',
  categories_remove_category_sample:
    'Möchten Sie die Probe wirklich entfernen?',
  categories_list_categories_samples: 'Proben',
  tasting_help: 'Verkostungshilfe',
  common_back: 'Zurück',
  common_next: 'Nächste',
  common_sample_id: 'Proben-ID',
  common_sample_date: 'Beispieldatum',
  common_duplicate: 'Duplikat',
  tasting_raw_materials: 'Rohes Material',
  tasting_example_sensory_risk:
    'Beispiel für das sensorische Hauptrisiko: Nichtübereinstimmung mit dem erwarteten Geschmack',
  tasting_procedure: 'Verfahren',
  tasting_sample_preparation: 'Probenvorbereitung',
  tasting_help_comments: 'Kommentare',
  common_collection_point: 'Sammelpunkt',
  common_collection_date: 'Abholtermin',
  common_brew_number: 'Brühzahlen/MES Anzahl',
  common_need_explain_nok: 'Sie können keine Proben NOK ohne Kommentar haben!',
  common_percent_ok: 'Prozentsatz OK',
  common_show_all: 'Zeige alles',
  common_import: 'Importieren',
  common_download_template: 'Vorlage herunterladen',
  sample_import_message: 'xlsx',
  common_template: 'Vorlage',
  common_yes: 'Jawohl',
  common_no: 'Nein',
  common_need_volte_first:
    'Sie können keine Proben ohne vorherige Abstimmung kommentieren!',
  common_name_template: 'Vorlagenname',
  interaction_log: 'Interaktionsprotokoll',
  common_message_zone_empty: 'Zone nicht erlaubt leer oder leer',
  common_message_country_empty: 'Land nicht zulässig leer oder leer',
  common_message_site_empty: 'Brauerei nicht erlaubt, leer oder blanco',
  user_new_profile_save: 'Neues Profil erstellt',
  common_in_privileges: 'in Privilegien',
  common_download_files: 'Dateien herunterladen',
  pdf_viewer: 'Betrachter-PDF',
  common_all: 'Alle',
  common_continue: 'Weitermachen',
  common_minimum_score: 'Min',
  common_maximum_score: 'Max',
  common_average_score: 'Durchschnitt',
  file_upload_fail: 'Fehler beim Hochladen der Datei',
  file_upload_success_with_error:
    'Der Upload war bis auf folgende Punkte erfolgreich:',
  missing_fields: 'fehlende Felder',
  column_error: 'Spaltenfehler',
  common_line: 'Leitung',
  file_no_samples: 'Keine Beispiele in der Datei gefunden!',
  file_success_upload: 'Datei erfolgreich hochladen!',
  file_duplicated_kpi_project: 'Duplizierter KPI oder Projekt',
  round_schedule_maximum_length:
    'Die maximale Länge des runden Namens beträgt 40 Zeichen.',
  sample_malt_varieties: 'Malz-Sorte',
  error_sample_already_in_round: 'Die Probe ist schon in der Runde',
  malt_varieties_has_no_english_name:
    'Sie müssen mindestens einen Malzsortennamen haben.',
  malt_varieties_duplicate:
    'Achtung: Es gibt eine Vielzahl von Malzen mit diesem Namen',
  malt_varieties_remove: 'Vielfalt entfernen',
  malt_varieties_new: 'Neue Sorte',
  reports_no_dates: 'Startdatum und Enddatum sind obligatorisch!',
  reports_date_range_message:
    'Der Datumsbereich darf nicht größer als 1 Jahr sein',
  common_error: 'Hoppla! Etwas ist schief gelaufen. Bitte versuche es erneut',
  common_horizontal: 'Horizontal',
  common_unit: 'Einheit',
  common_supplier: 'Anbieter',
  common_flavor: 'Geschmack',
  concentration_of_the_base_beer: 'Konzentration des Grundbieres',
  threshold_aml_method: 'AML-Methode (aufsteigende Limitmethode)',
  threshold_component_concentration: 'Komponentenkonzentration',
  threshold_sample_different_help: 'Welche Probe ist anders?',
  threshold_each_set_random_order: 'In jedem Satz zufällige Reihenfolge.',
  threshold_select_base_beer: 'Basisbier auswählen',
  common_unity: 'Einheit',
  common_finish_session: 'Sitzung beenden',
  review_submit_threshold: 'Möchten Sie wirklich speichern?',
  threshold_coder_wrong:
    'Dein Code ist falsch. Bitte teilen Sie den korrekten Code mit!',
  threshold_didnt_enter_the_code: 'Bitte geben Sie Ihre Sitzplatznummer ein',
  code: 'Code',
  threshold_samples_required: 'Grundbier und Aroma sind erforderlich',
  threshold_information_helper_sample:
    'Klicken Sie zum Speichern der Schwellenwertbeispielinformationen auf die Schaltfläche Speichern, um Ihre Änderungen beizubehalten',
  code_digits: 'Geben Sie den Ihnen zugewiesenen 3-stelligen Code ein',
  common_batch_code: 'Batch-Code',
  threshold_sample_spiked_with:
    'Wählen Sie bei jedem Satz die Probe aus, mit der versetzt wurde ',
  threshold_code_already_tasted:
    'Dieser Code wurde bereits von einem anderen Benutzer probiert. Geben sie einen anderen Code ein.',
  common_kpi_brands: 'KPI-Marken',
  common_year: 'Jahr',
  common_new_kpi_brand: 'Neue KPI-Marke',
  common_action: 'Aktion',
  common_comment_by_category: 'Kommentare nach Kategorie',
  common_alphabetic_comment: 'Alphabetische Kommentare',
  common_name_pts_code: 'PTS code',
  common_name_pts_code_placeholder: 'Code',
  common_name_process_item: 'Artikel bearbeiten',
  common_name_process_item_placeholder: 'Artikel',

  kpi_only_numbers_warning: 'Akzeptieren Sie nur Zahlen für Zielwerte',
  kpi_max_number_range_warning: 'Ihre Zielmarke muss zwischen 1 und 9 liegen',
  kpi_already_selected_brand_warning:
    'Sie haben diese Marke ausgewählt, bitte wählen Sie eine andere Marke',
  kpi_no_kpi_informed_warning: 'Sie haben keinen Mehrwert',
  kpi_select_year_warning: 'Wählen Sie ein Jahr aus',
  kpi_select_site_warning: 'Wählen Sie eine Website aus',
  kpi_existing_configuration_warning:
    'Sie haben KPI-Werte vom selben Standort und Jahr',
  kpi_target_empty_warning: 'Ein Ziel einer Ihrer Marken ist leer',
  kpi_saved_successfully: 'Ihre Werte wurden erfolgreich gespeichert!',
  kpi_submitted_successfully: 'Ihre Werte wurden erfolgreich übermittelt!',
  kpi_max_decimals_warning:
    'Ihr Zielwert muss bis zu 3 Dezimalstellen betragen',
  kpi_count_as_kpi_sample: 'Zählen Sie als KPI',
  report_list_report_kpi: 'Meldet KPI',
  report_list_report_taste: 'Berichte Geschmack',
  select_brand_to_filter: 'Wählen Sie eine globale Marke!',
  select_year_to_filter: 'Wählen Sie ein Jahr!',
  samples_uncheck_kpi_sample_tooltip:
    'Deaktivieren Sie dieses Kontrollkästchen, wenn diese Stichprobe nicht im KPI gezählt werden soll',
  submission_type: 'Tipo de compromiso',
  reports_mandatory_zone_filter: 'Wählen Sie mindestens eine Zone aus',
  custom_scale_title: 'Maßgeschneiderte Waage',
  custom_scale_grid_header_score: 'Punktzahl',
  custom_scale_grid_header_ageind_scale: 'Forced-Aging-Skala',
  the_value_cannot_be_null: 'Die sind niemals Leer!',
  unique_id: 'Eindeutig identifiziert',
  report_filter_rounds_description:
    'Sie müssen die Pflichtfelder auswählen, um Rundenoptionen zu laden.',
  reports_mandatory_test_type: 'Wählen Sie den Testtyp aus.',
  round_reports_month_warning:
    'Der Zeitraum zwischen Startdatum und Enddatum darf maximal 6 Monate betragen.',
  zone_can_not_empty: 'Region darf nicht leer sein',
  error_loading_data:
    'Fehler beim Laden der Daten. Bitte überprüfen Sie Ihre Verbindung und laden Sie neu.',
  flavor_identification: 'Geschmacksidentifikation',
  beer_batch: 'Bier Charge',
  list_of_beers: 'Bier Liste',
  random: 'zufällig',
  preview: 'Vorschau',
  select_the_flavor: 'Wählen Sie den Geschmack',
  flavor_threshold: 'Geschmacksschwelle',
  input_digital_number: 'Geben Sie die digitale Nummer ein',
  concentration: 'Konzen tration',
  flavor_order: 'Geschmacksfolge',
  last: 'Letzte',
  tasting: 'Verkostung',
  confirmation: 'Bestätigung',
  do_you_confirm_the_evaluation_of_this_sample:
    'BestÃƒÂ¤tigen Sie die Auswertung dieser Probe?',
  confirm: 'bestätigen',
  evaluate: 'Bewertet',
  correct_answer: 'Korrekte Antwort',
  threshold: 'Schwelle',
  let_a_comment_about_the_tasting:
    'Hinterlassen Si,e einen Kommentar zur Verkostung',
  send: 'Senden',
  right_now: 'Sofort',
  minute_ago: 'Vor einer Minute',
  hour_ago: 'Vor einer Stunde',
  day_before: 'der Tag zuvor',
  year_ago: 'Vor einem Jahr',
  add_a_flavor: 'Füge einen Geschmack hinzu',
  soft_drinks: 'Alkoholfreie Getränke',
  soft_drinks_brand: 'alkoholfreie Getränke',
  soft_drinks_brand_target: 'Markenziel für Erfrischungsgetränke',
  column_options: 'Spaltenoptionen',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Wählen Sie aus, welche der Spalten angezeigt werden soll.',
  countdown: 'Countdown',
  less_than_five_minutes:
    'Diese Verkostung endet in 5 Minuten, wenn Sie fortfahren müssen, wenden Sie sich bitte an den Administrator, um das Enddatum zu verschieben.',
  the_countdown_to_the_evaluation_has_ended:
    'Es konnten keine kommentare abgegeben werden, weil der countdown beendet war.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Rechne, wie lange es dauert, bis diese proben probiert werden',
  only_six_flavors_can_be_added_at_most:
    'Es können nur bis zu sechs Geschmacksrichtungen hinzugefügt werden!',
  concentration_of_the_flavor: 'Konzentration der Sparer',
  input_value: 'Eingabewert',
  Concentration_of_the_flavor_is_required:
    'Konzentration des Geschmacks ist erforderlich',
  previous_round: 'Vorherige Runde',
  next_round: 'Nächste Runde',
  the_end_time_is_greater_than_the_current_time:
    'Die Endzeit muss größer als die aktuelle Zeit sein, wenn Sie den gleichen Tag wie den aktuellen Tag auswählen, wählen Sie zuerst die Stunde und Minute aus!',
  check_the_answer_at_the_end_of_the_countdown:
    'Der countdown ist noch nicht beendet. Die antwort erhalten sie nach dem countdown',
  incremental_step: 'Inkrementeller Schritt',
  set3_concentration: 'Set3 Konzentration',
  concentration_increments_between_levels:
    'Konzentrationsanstiege zwischen Ebenen',
  incremental_set3_concentration_required:
    'Inkrementaler Schritt und Set3 Konzentration sind erforderlich',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'Die Sprintnummer muss größer als 0 sein.',
  sprint_notes_at_least_one_note:
    'Sprintnotizen müssen mindestens eine Notiz enthalten.',
  sprint_patch_notes_success_update:
    'Sprint-Patchnotizen erfolgreich aktualisiert.',
  sprint_patch_notes_error_update:
    'Die Notizen konnten nicht gespeichert werden.',
  update_sprint_patch_notes: 'Aktualisieren Sie die Sprint-Patchnotes',
  sprint_number: 'Sprintnummer',
  sprint_patch_notes: 'Sprint-Patchnotizen',
  note: 'Notiz',
  concentration_increments_between_levels:
    'Konzentrationsanstiege zwischen Ebenen',
  incremental_set3_concentration_required:
    'Inkrementaler Schritt und Set3 Konzentration sind erforderlich',
  select_all: 'Alle auswählen',
  please_add_samples_first: 'Bitte fügen Sie zuerst ein Muster hinzu',
  concentration_increments_between_levels:
    'Konzentrationsanstiege zwischen Ebenen',
  incremental_set3_concentration_required:
    'Inkrementaler Schritt und Set3 Konzentration sind erforderlich',
  reverse: 'Rollback',
  new_test: 'Tests hinzugefügt',
  triangle_test_name: 'Name des Triangulationstests',
  triangle_test: 'Triangulationstest',
  you_can_not_set_three_identical_samples:
    'Sie können nicht dieselben drei Optionen festlegen',
  its_mandatory_to_input_test_name: 'Bitte geben Sie den Testnamen ein',
  its_mandatory_to_input_sample_information:
    'Bitte füllen Sie die Beispielinformationen aus',
  attention: 'prompt',
  test_number: 'Seriennummer des Triangulationstests',
  you_selected_the: 'Sie haben gewählt',
  next_sample: 'Nächstes Beispiel',
  remove_triangle_test: 'Diesen Test löschen',
  you_can_only_select_up_to_five:
    'Maximal fünf Auswahlmöglichkeiten sind zulässig',
  test: 'Test',
  confirm_remove: 'Löschen des Tests bestätigen?',
  reports_mandatory_zone_country_site_filter:
    'Regionen, Länder, Fabriken sind erforderlich',
  reports_mandatory_brand_filter: 'Wählen Sie mindestens eine Marke aus',
  reports_by_producing_site: 'Vollständiger Analysebericht',
  download_label: 'Schaltfläche "Herunterladen',
  standard: 'Richtwert',
  input_test: 'Ergebniseingabe',
  detecting: 'Entdeckend...',
  connected: 'Verbunden',
  current_taster: 'Aktuelle Gutachter',
  alcohol_concentration: 'Alkoholkonzentration',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Ihre Blutalkoholkonzentration ist zu hoch. Bitte später erneut testen!',
  alcohol_test_qualified: 'Bestehen Sie den Alkoholtest',
  configuration: 'Confi Gulation',
  input_result: 'Ergebniseingabe',
  maximum_alcohol_concentration: 'Überschüssige Alkoholkonzentration',
  breathalyzer: 'Alkoholdetektor',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Bitte geben Sie eine positive Zahl bis zu {{field}} Dezimalstellen ein',
  you_must_input: 'Sie müssen eingeben',
  driving: 'Autofahren',
  test_time: 'Testzeit',
  input_type: 'Typ eingeben',
  list_breathalyzer: 'Atemanalysator',
  Manual: 'Handbuch',
  Breathalyzer: 'Atemanalysator',
  you_do_not_have_privilege_to_export_this_report:
    'Sie haben keine Berechtigung, diesen Bericht zu exportieren',
  competitor_beer: 'Wettbewerber Bier',
  split_round: 'Runde teilen',
  reset: 'Zurücksetzen',
  how_many_rounds_splitting_do_you_want_to_make:
    'Wie viele Runden der Spaltung wollen Sie machen',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Eine bereits geteilte oder laufende Runde kann nicht geteilt werden',
  hide_sample_details: 'Beispieldetails ausblenden',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Daten werden vor dem Verkoster verborgen: Brauerei, Produktionsdatum, Marke, Malzsorte und Probe ID',
  data_will_be_hidden_for_ok_nok:
    'Alle relevanten Daten werden vor den Verkostern verborgen.',
  you_can_not_add_duplicate_attributes:
    'Wiederholende eigenschaften können nicht gesetzt werden.',
  menu: 'MENÜ',
  link_breathalyzer: 'Verbunden',
  not_link_breathalyzer: 'Nicht verbunden',
  breathalyzer_to_link: 'Atemanalysator anschließen',
  breathalyzer_can_not_to_link_for_this_browser:
    'Die Funktion des Atemanalyzers wurde entwickelt, um auf Google Chrome mit PC-Geräten zu arbeiten.',
  reopen: 'Wiederöffnen',
  round_reopen_message:
    'Sind Sie sicher, dass Sie die Runde wieder eröffnen wollen',
  common_production_line: 'Produktionslinie',
  common_packaged_beer_data_code: 'Datencode für verpacktes Bier',
  common_masking_process_for_bottles: 'Maskierungsverfahren für Flaschen',
  common_str_number_days_over_aged: 'Anzahl der Tage bei Überalterung',
  common_over_aged_beer: 'Überaltertes Bier',
  sample_successfully_swapped: 'Der probenaustausch ist gut gelungen.',
  scoring: 'Bewertung',
  configure_available_flavors: 'Verfügbare Geschmacksrichtungen einrichten',
  process_check: 'Prozesskontrolle',
  new_process_check: 'Neue Prozessprüfung',
  edit_process_check: 'Prozessprüfung bearbeiten',
  remove_process_check: 'Prozessprüfung entfernen',
  common_package: 'Paket',
  common_department: 'Abteilung',
  common_shift: 'Verschiebung',
  common_tank: 'Tank',
  common_oknok: 'OKNOK',
  common_remark: 'Bemerkung',
  show_all_process_check: 'Alle Prozessprüfung anzeigen',
  show_all_process_check_help_circle:
    'Markieren sie dieses feld, um die daten anzuzeigen, die in einem zeitraum Von einem jahr erstellt wurden und die noch im letzten jahr erstellt werden müssen',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'Das Stichprobendatum kann nicht früher als die aktuelle Zeit sein!',
  common_job_position: 'Position.',
  decimal_sep: 'Dezimaltrennzeichen',
  is_swapped_to: 'wird getauscht auf',
  review_warning_comment_title:
    'Ich sehe, sie haben ein undefiniertes beispiel!',
  continuous_typing: 'Eingabefolge eingeben',
  continuous_typing_tips:
    "Zählen Sie die Eingabe von 'Attribut' nach Auswahl/Eingabe von 'Intensität'.",
  BSSI_Reports: 'BSSI Berichte',
  data_analysis_sip: 'Datenanalyse - SIP',
  select_the_color: 'Wählt die farbe.',
  Color: 'Farben?',
  download_document: 'Datei herunterladen.',
  Month_of_Discontinued_Production:
    'Der monat, in dem die produktion eingestellt wird.',
  Suspension_List: 'Liste: eindämmung der produktion',
  New_Suspension: 'Antrag auf einstellung der produktion',
  new_suspension_page: 'Neuer einstellung der produktion',
  edit_suspension_page: 'Bearbeiten sie den abbruch der übertragung',
  Brand_name: 'markenname',
  List_Brewery_Production_Suspension:
    'Liste der fabriken zur einstellung der produktion',
  Upload_successfully: 'Datei erfolgreich hochgeladen',
  training_materials: 'Als vorbereitung auf die ausbildung klicken.',
  Remove_Suspension: 'Fahrer ohne kragen: ',
  Add_Absence_Reason: "Ursachen für's fernbleiben hinzufügen",
  Reason: 'Gründe?',
  You_have_duplicate_absence_dates: 'Du hast wohl ein doppeltes datum',
  Remove_Absence: 'Löschen sie nichts. ',
  Absence_Title: 'Wählen sie bitte den zeitpunkt und den grund für das fehlen',
  Shipping_Site: 'Die schiffsrouten.',
  shipping_received: 'Du bist schiffbrüchtig.',
  receive_sample_alert: 'Sind sie sicher, dass sie die proben bekommen haben?',
  receive_uncheck_alert: 'Soll ich wirklich absagen?',
  all_received: 'Alles einsammeln.',
  not_all_received: 'Nicht alle.',
  not_for_shipping: 'Er ist nicht transportiert.',
  receive_hint: 'Wählen sie alle seiten, die dem beispiel folgen',
  absence_reason: 'Die gründe für das fehlen',
  Page1: 'Die seite {{field}}',
  Search: 'Suche läuft.',
  page_total: 'Gesamtzahl',
  start_date: 'Startdatum',
  end_date: 'Enddatum',
  ok_for_all: 'Das ist für alle das beste',
  its_mandatory_to_select_sample_A_and_sample_B:
    'Wir müssen A und B probieren.',
  its_mandatory_to_select_sample_A: 'Muster A muss ausgewählt werden.',
  its_mandatory_to_select_sample_B: 'Muster B muss ausgewählt werden.',
  triangle_comment: 'die kritiker',
  select_sample: 'Nehmen sie eine probe',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Beispieldateifilter, standardmäßig die letzten sieben Tage, exportiert nach Excel basierend auf dieser gefilterten Dauer.',
  select_all_flavor: 'Auswahl für alle.',
  deselect_all: 'Deaktivieren sie alle optionen.',
  email_distribution: 'E-Mail-Verteilung',
  new_email_distribution: 'Neue E-Mail-Verteilung',
  edit_email_distribution: 'E-Mail-Verteilung bearbeiten',
  distribution_type: 'Verteilungstyp',
  the_score_entered_is_invalid:
    'Die eingegebene Punktzahl ist ungültig. Bitte beachten Sie die Notenskala und geben Sie Ihre Punktzahl erneut ein.',
  service_now:
    'Klicken sie hier, um einen terminkalender zu erstellen, um probleme zu melden und hilfe zu erhalten',
  service_now_tips:
    'Hey, wenn Sie ein Problem sehen oder um Unterstützung bitten möchten, klicken Sie hier und melden Sie es direkt in serviceNow. Unser Support-Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen.',
  done: 'Fertig',
  changes_you_made_may_not_be_saved:
    'Änderungen, die Sie vorgenommen haben, werden möglicherweise nicht gespeichert. Möchten Sie fortfahren und Änderungen verlieren?s',
  cannel_auto_save_tips:
    'Jetzt ist die Leistung der Verkostungseite verbessert, denken Sie bitte daran, auf Probe beenden zu klicken, um Ihr Verkostungsergebnis zu speichern und einzureichen.',
  list_email_page_tips:
    'Dies ist die beschreibung dafür, wie die richtige kpi-probe gesetzt werden kann, um sicherzustellen, dass die regionale monatliche berichterstattung eine genaue darstellung des mk-ausfalls ihrer region enthält',
  list_email_page_helps:
    'Die Anleitung, wie Sie KPI-Stichprobe einrichten, um genaue Daten in Ihrem Zone Monatsbericht wiederzugeben.',
  kpi_instruction: 'KPI-Anweisung',
  group_no_recipients_message: 'Es wird mindestens ein Empfänger benötigt.',
  the_current_distribution_type_already_exists:
    'Der aktuelle Distributionstyp existiert bereits, bitte wählen Sie den Typ erneut aus oder geben Sie die Liste zurück.',
  distribution_type_message_empty:
    'Verteilungsart nicht erlaubt leer oder leer',
  add_samples: 'Probe(n) zur Runde hinzufügen',
  copy_this_sample: 'Duplizieren der Probe',
  remove_this_sample: 'Probe entfernen',
  multiple_selection_tips: `Sie können mehrere Proben desselben Probentyps auf einmal auswählen. Klicken Sie auf '+' und '-', um es auszuprobieren.`,
  all_add_once_tips: `Klicken Sie auf die Schaltfläche "Alle auswählen", um automatisch 1 Probe zu jeder ausgewählten Probenart hinzuzufügen.`,
  user_non_abi_user: 'Neuer temporärer Benutzer',
  language_of_email_invitation: 'Sprache der E-Mail-Einladung',
  add_non_abi_users_to_round:
    'Der temporäre Nutzer erhält eine E-Mail-Einladung mit einem Link zur Teilnahme an der jeweiligen Verkostung.',
  change_non_abi_users_to_round:
    'Zeitweilige Verkoster, die Sie eingeladen haben, erhalten eine E-Mail-Einladung mit einem Link zur Teilnahme an dieser Verkostung.',
  guest: 'Gast',
  email_to_all: 'E-Mail an alle',
  send_email: 'Einladung per E-Mail senden',
  temporary_non_abi_taster: 'Temporärer Verkoster',
  user_type: 'Benutzertyp',
  email_invitation_delivery_request_sent: 'Einladungs-E-Mail-Anfrage gesendet.',
  incorrect_e_mail_formatting: 'Falsche E-Mail-Formatierung',
  all_tasters: 'Alle Verkoster',
  key_tasters: 'Key Tasters',
  non_key_tasters: 'Nicht-Schlüsseltaster',
  temporary: 'Temporärer Benutzer',
  maximum_number_of_samples_is: 'Die maximale Anzahl von Proben ist',
  sample_added: 'Probe hinzugefügt',
  click_to_view_scale_of_flavour_intensities:
    'Klicken Sie auf den Link, um die detaillierten Intensitätsskalen zu sehen',
  list_next_week_plan: 'Plan für die nächste Woche',
  common_time_zone: 'Zeitzone',
  common_last_response_time: 'Letzte Antwortzeit',
  new_week_plan: 'Neuer Wochenplan',
  invited_week: 'Eingeladene Woche',
  email_language: 'E-Mail-Sprache',
  save_and_send_email: 'E-Mail speichern und senden',
  common_start_time: 'Startzeit',
  common_end_time: 'Endzeit',
  session_name_email_subject: 'Name der Sitzung / E-Mail-Betreff',
  location: 'Standort',
  edit_week_plan: 'Wochenplan bearbeiten',
  email_response: 'E-Mail-Antwort',
  round_remove_plan: 'Sind Sie sicher, dass Sie den Plan löschen möchten',
  view_week_plan: 'Wochenplan ansehen',
  are_you_confirming_to_send_the_confirmation_email:
    'Bestätigen Sie den Versand der Bestätigungs-E-Mail',
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation:
    'Alle 10 Minuten aktualisiert das System die E-Mail-Antwort auf die Einladung der nächsten Woche.',
  documents_upload_tips: 'Datei-Uploads unterstützen nur {{field}}',
  ignore_submit: 'Ignorieren&Absenden',
  if_count_as_kpi:
    'Wenn dieses Feld leer ist, werden die KPIs als "Count as KPI" kennzeichnet',
  confirmed_count: 'Bestätigte Anzahl',
  accepted: 'Akzeptiert',
  declined: 'Abgelehnt',
  no_response: 'Keine Antwort',
  reminder_time: 'Erinnerung Zeit',
  are_you_sure_you_want_to_change_the_week_of_invitation:
    'Sind Sie sicher, dass Sie die von Ihnen gewählte Start-/Endzeit der Sitzung entfernen und zu einer anderen Eingeladenen Woche wechseln möchten',
  apply_to_all_sessions_location_tasters: `Anwenden von "Standort" und "Tasters" auf folgende Sitzungen`,
  mcs_tasting: 'MCS-Verkostung',
  mcs_tasting_tips:
    'Wählen Sie Ja, wenn diese Runde MCS/KPI-Proben enthält. Das System wendet dann den entsprechenden KPI-Code für die Probenmarke an.',
  round_samples_shuffle_confirm:
    'Sind Sie sicher, dass Sie die Sets mischen wollen?',
  all_count_as_kpi: 'Alle zählen als KPI',
  msc_tasting_tour_mask:
    'Das System identifiziert automatisch den entsprechenden KPI-Code auf der Grundlage der gezählten Stichprobe mit dem richtigen KPI-Monat, so dass Sie NICHT verschiedene Marken in einer Runde aufteilen müssen.',
  competition: 'Wettbewerb',
  copy_link: 'Link kopieren',
  open_link: 'Link öffnen',
  common_download_img: 'IMG herunterladen',
  please_select_taster: 'Bitte wählen Sie eine Kostprobe',
  user_principal_name: 'Nombre de usuario principal',
  user_status: 'Estado del usuario',
  termination_date: 'Fecha de finalización',
  function_level: 'Nivel de función',
  last_update_time: 'Hora de la última actualización',
  system_related: 'Relacionados con el sistema',
  workday_related: 'Día del Trabajo',
  employee_id: 'Mitarbeiter-ID',
  zitec_project_name: 'Name des Projekts',
  applicant: 'Antragsteller',
  applicant_date: 'Datum des Antrags',
  test_method: 'Testmethode',
  liquid_category: 'Flüssigkeits-Kategorie',
  conditions: 'Bedingungen',
  time_points: 'Zeitpunkte',
  execution_date: 'Ausführungsdatum',
  execution_by_time_points: 'Berechnetes Ausführungsdatum nach Zeitpunkten',
  invitation_start_time: 'Einladung Startzeit',
  invitation_end_time: 'Einladung Endzeit',
  invitation_reminder: 'Einladung Erinnerung',
  application_form: 'Antragsformular',
  application_form_result: 'Anmeldeformular Ergebnis',
  upload_result: 'Ergebnis hochladen',
  zitec_sessions: 'ZITEC-Sitzungen',
  zitec_id: 'ZITEC ID',
  new_zitec_session: 'Neue ZITEC-Sitzung',
  common_search_applicant: 'Antragsteller suchen',
  history_record: 'Datensatz',
};
