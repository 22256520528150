import ListZITEC from './listZITEC/ListZITEC';
import NewZITEC from './newZITEC/NewZITEC.jsx';

export default {
  privilege: 'PROCESS_CHECK',
  routeModule: {
    path: '/list_ZITEC',
    privilege: 'PROCESS_CHECK',
    component: ListZITEC,
    key: 'zitec_sessions',
    routeComponents: [
      {
        path: '/new_ZITEC',
        component: NewZITEC,
        key: 'New_ZITEC',
      },
    ],
  },
};

