export default {
  sample_no_production_date_message: 'Je nutné zvolit datum výroby.',
  free_comment: 'Volný komentář',
  round_list_rounds: 'Kola',
  round_edit_round: 'Upravit kolo',
  round_new_round: 'Nové kolo',
  round_remove_round: 'Jste si jisti, že chcete kolo smazat',
  round_schedule: 'Plán',
  round_tasters_unselect_all: 'Zrušte výběr všech ochutnávačů',
  round_tasters_select_all: 'Vybrat všechny degustátory',
  round_close: 'Zavřít kolo',
  round_close_message: 'Jste si jisti, že chcete kolo ukončit',
  round_create_site_message: 'Vytváříte kolo, které není pro vaší lokalitu',
  round_closed_message: 'Uzavřená kola nelze upravovat',
  round_status_created: 'Vytvořeno',
  round_status_samples: 'Vzorky',
  round_status_scheduled: 'Naplánované',
  round_status_tasters: 'Degustátoři',
  round_status_in_progress: 'Probíhající',
  round_status_finished: 'Dokončeno',
  round_status_closed: 'Uzavřeno',
  round_schedule_start_finish_date:
    'Datum zahájení nesmí být větší než datum ukončení!',
  round_schedule_finish_date_no_start_date:
    'Nejprve musíte vybrat Datum zahájení!',
  round_disabled_discussion_tooltip:
    'Nemůžete přejít na Diskuzi nedokončeného kola',
  round_delete_other_users_rounds:
    'Nemáte oprávnění odstranit kolo, které jste nevytvořili vy sami',
  round_randomize_samples_order_button: 'Zamíchat',
  round_block_results_tooltip:
    'Uzamknout výsledky tohoto kola před stahováním na obrazovce přehledů. Pokud je tlačítko deaktivováno, nemáte oprávnění uzamknout výsledky kola z tohoto umístění.',
  round_block_results_confirm:
    'Jste si jisti, že chcete zablokovat/odblokovat výsledky kola ',
  round_block_results_no_privilege:
    'Nemáte oprávnění blokovat/odblokovat výsledky kola z tohoto umístění.',
  sample_list_samples: 'Vzorky',
  sample_edit_sample: 'Upravit vzorek',
  sample_new_sample: 'Nový vzorek',
  sample_remove_sample: 'Odstranit vzorek',
  sample_upload_document: 'Nahrát dokumenty',
  sample_download_documents: 'Vzorové dokumenty',
  sample_tasted_dates: 'Datum ochutnávky',
  sample_upload_message:
    'Chcete-li nahrát více dokumentů, přidejte je do složky ',
  sample_not_for_kpi: 'Ne pro KPI',
  sample_package: 'Balíček',
  sample_volume: 'Objem',
  sample_production_date: 'Datum výroby',
  sample_expire_date: 'Datum expirace',
  sample_trial_identifier: 'Identifikátor zkoušky',
  sample_project_name: 'Název projektu',
  sample_linked: 'Souvisí s kolem',
  sample_expire_bigger_than_production:
    'Datum výroby nesmí být větší než datum expirace',
  sample_no_site_message:
    'Pro vytvoření vzorku je povinné vybrat Zónu, Zemi a Místo.',
  sample_no_brand_message: 'Je povinné vybrat značku (Brand).',
  sample_duplicated_kpi_message:
    'Pro vybraný měsíc, značku a lokalitu již existuje KPI a nebude možné jej uložit.',
  sample_duplicated_project_message:
    'Pro tuto lokalitu a značku již existuje vzorek s tímto názvem projektu a identifikátorem zkoušky a nebude možné jej uložit.',
  sample_project_no_name_or_trial:
    'Název projektu a Identifikátor zkoušky jsou při vytváření vzorku typu projektu povinné.',
  sample_production_to_project_confirm_message:
    'Měníte typ tohoto vzorku z PRODUCTION na PROJECT. Přejete si pokračovat?',
  sample_project_to_production_confirm_message:
    'Měníte typ tohoto vzorku z PROJECT na PRODUCTION. Přejete si pokračovat?',
  sample_no_volume_message:
    'Balení a Objem jsou povinné při vytváření/úpravě vzorku.',
  sample_id_code_message: 'Uveďte prosím úplné číslo šarže',
  sample_show_all_samples_hint:
    'Zobrazení vzorků vytvořených do šesti měsíců ode dneška. Pokud chcete zobrazit i starší vzorky, zaškrtněte toto políčko.',
  sample_show_all_samples_label: 'Zobrazit všechny vzorky',
  package_duplicate: 'Balení s tímto názvem již existuje',
  common_production: 'Výroba',
  common_project: 'Projekt',
  group_list_groups: 'Skupiny',
  group_edit_group: 'Upravit skupinu',
  group_new_group: 'Nová skupina',
  group_remove_group: 'Odstranit skupinu',
  group_close_modal: 'Zavřít',
  group_duplicated_user: 'Tento uživatel již byl přidán do této skupiny!',
  group_no_name_message: 'Je nutné zadat název skupiny',
  group_no_zone_message: 'Je nutné vybrat zónu',
  group_no_users_message: 'Je nutné vybrat alespoň jednoho ochutnávače',
  family_list_families: 'Styly',
  family_edit_family: 'Upravit styl',
  family_new_family: 'Nový styl',
  family_remove_family: 'Odebrat styl',
  family_has_no_english_name:
    'Pozor: název stylu musí být povinně v angličtině',
  brand_list_brands: 'Značky',
  brand_edit_brand: 'Upravit značku',
  brand_new_brand: 'Nová značka',
  brand_remove_brand: 'Odstranit značku',
  brand_profile_picture: 'Nahrát profilový obrázek piva',
  brand_upload_brand_logo: 'Nahrát logo značky',
  brand_duplicate: 'Varování: Existuje pivo s tímto názvem',
  brand_has_no_english_name:
    'Musíte mít alespoň jeden název značky a k němu odpovídající profil značky.',
  brand_upload_help_message: 'Pouze soubory PNG a JPG',
  brand_has_no_product_type: 'Musíte vybrat typ produktu',
  package_list_packages: 'Balení',
  package_edit_package: 'Upravit balení',
  package_new_package: 'Nové balení',
  package_remove_package: 'Odebrat balení',
  package_has_no_english_name:
    'Pozor: název balení musí být povinně v angličtině',
  package_volumes: 'Objemy',
  package_new_volume: 'Nový objem',
  package_remove_volume: 'Odebrat objem',
  degree_list_degrees: 'Intenzity',
  degree_edit_degree: 'Upravit intenzitu',
  degree_new_degree: 'Nová intenzita',
  degree_remove_degree: 'Odstranění intenzity',
  category_list_categories: 'Kategorie',
  category_edit_category: 'Upravit kategorii příchutí',
  category_new_category: 'Nová kategorie příchutí',
  category_remove_category: 'Odstranění kategorie příchutí',
  comment_list_comments: 'Atributy',
  comment_edit_comment: 'Upravit atribut příchuti',
  comment_new_comment: 'Nový atribut příchuti',
  comment_remove_comment: 'Odstranění atributu příchuti',
  user_list_users: 'Uživatelé',
  user_edit_user: 'Upravit uživatele',
  user_new_user: 'Nový dodavatel ABI',
  user_export_users: 'Export uživatelů',
  user_list_profiles: 'Profily',
  user_profile_name: 'Název profilu',
  user_profile_select: 'Vybrat profil',
  user_profile_remove: 'Odebrat profil',
  user_profile_save: 'Uložit profil',
  user_remove_user: 'Odebrat uživatele',
  user_use_terms: 'Přijaté podmínky používání',
  report_list_report: 'Zprávy',
  report_end_date: 'Datum ukončení',
  common_privileges: 'Oprávnění',
  common_abi_name: 'ABI jméno',
  common_id: 'ID',
  common_ranked: 'Pořadí',
  common_week: 'Týden',
  common_weeks: 'Týdny',
  common_days: 'Dny',
  common_category: 'Kategorie příchutí',
  common_category_sample: 'Kategorie',
  common_degree: 'Intenzita',
  common_comment: 'Atribut příchuti',
  common_status: 'Status',
  common_zone: 'Zóna',
  common_bu: 'BU',
  common_country: 'Země',
  common_site: 'Místo',
  common_start_date: 'Datum zahájení',
  common_finish_date: 'Datum ukončení',
  common_round_type: 'Typ testu',
  common_round: 'Kolo',
  common_round_id: 'ID kola',
  common_tasters: 'Degustátoři',
  common_taster: 'Degustátor',
  common_sample_performance: 'Ukázkový výkon',
  common_taste: 'Chuť',
  common_key_taster: 'Klíčový degustátor',
  common_sample: 'Vzorek',
  common_samples: 'Vzorky',
  common_sample_type: 'Typ vzorku',
  common_family: 'Styl',
  common_brand: 'Značka',
  common_product_type: 'Typ výrobku',
  common_temperature: 'Podmínky skladování',
  common_storage_times: 'Doba skladování',
  common_sku: 'SKU',
  common_batch: 'ID kód',
  common_kpi_month: 'KPI Měsíc',
  common_logo: 'Logo',
  common_name: 'Název',
  common_notes: 'Poznámky',
  common_email: 'E-mail',
  common_language: 'Jazyk',
  common_terms_of_use: 'Podmínky použití',
  common_new: 'Nový',
  common_profile: 'Profil',
  common_filter: 'Filtrování uživatelů',
  common_download: 'Stáhnout',
  common_save: 'Uložit',
  common_cancel: 'Zrušit',
  common_search_users: 'Vyhledávání uživatelů',
  common_search_group: 'Vyhledávání skupiny',
  common_search_taster: 'Vyhledávání degustátora',
  common_no_data: 'Nezobrazují se žádná data',
  common_loading: 'Načítání',
  common_cannot_be_empty: '{{field}} nemůže být prázdné',
  common_select_option: 'Vyberte možnost...',
  common_per_page: 'Řádky na stránku',
  common_attributes: 'Atributy',
  common_not_started: 'Nezahájeno',
  common_in_progress: 'Probíhá',
  common_finished: 'Dokončeno',
  common_start: 'Spustit',
  common_detail: 'Podrobnosti',
  common_position: 'Pozice',
  common_average_key_score: 'Průměrné key skóre',
  common_score: 'Skóre',
  common_score_scale: 'Stupnice skóre',
  common_intensity: 'Intenzita',
  common_report_not_have_data: 'Vybraná kola neměla žádný hlas',
  month_0: 'Leden',
  month_1: 'Únor',
  month_2: 'Březen',
  month_3: 'Duben',
  month_4: 'Květen',
  month_5: 'Červen',
  month_6: 'Červenec',
  month_7: 'Srpen',
  month_8: 'Září',
  month_9: 'Říjen',
  month_10: 'listopad',
  month_11: 'prosinec',
  dropdown_change_picture: 'Změnit obrázek',
  dropdown_language: 'Jazyk',
  dropdown_logout: 'Odhlášení',
  round_not_started: 'Nezahájeno',
  round_in_progress: 'Probíhá',
  round_finished: 'Dokončeno',
  round_filter_start: 'Spustit',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'Seznam kol',
  menu_tasting_session: 'Degustace',
  menu_panel_management: 'Správa panelu',
  menu_process_management: 'Správa procesu',
  menu_product_management: 'Správa produktů',
  menu_attributes_management: 'Správa atributů',
  tasting_finish_sample: 'Finální vzorek',
  tasting_comments: 'Komentáře',
  tasting_beer_profile: 'Profil piva',
  tasting_need_select_score_comments:
    'Pro pokračování musíte nastavit skóre a přidat alespoň jeden komentář...',
  tasting_finish_taste: 'Dokončit ochutnávku',
  common_no_comments: 'Žádné komentáře',
  review_submit_title:
    'Jste si jisti, že jste připraveni odeslat své výsledky?',
  review_submit_description: 'Později už nebudete moci upravovat',
  review_finish_taste: 'Dokončit ochutnávku',
  review_warning_sample_title: 'Máte neochutnané vzorky!',
  review_warning_sample_description:
    'Přejete si pokračovat bez jejich ochutnání?',
  review_warning_sample_review: 'Revize',
  review_warning_sample_ok: 'Pokračovat',
  review_warning_tie_sample_title: 'Máte svázané vzorky!',
  review_warning_tie_sample_description:
    'Nemůžete pokračovat bez jejich hodnocení!',
  review_warning_tie_sample_review: 'Revize',
  common_submit: 'Odeslat',
  common_discussion: 'Diskuse',
  common_not_informed: 'Není informován',
  discussion_order_number: 'Pořadí podle pozice',
  discussion_order_brewery: 'Pořadí podle pivovaru',
  discussion_order_ranking: 'Seřadit podle pořadí',
  discussion_order_brand: 'Pořadí podle značky',
  discussion_order_score: 'Pořadí podle skóre',
  discussion_order_key_score: 'Pořadí podle key skóre',
  discussion_order_overall_rank: 'Pořadí podle celkového pořadí',
  discussion_order_key_rank: 'Pořadí podle key pořadí',
  discussion_export_error: 'Soubor se nepodařilo uložit!',
  discussion_ranking: 'Pořadí',
  discussion_overall_ranking: 'Celkové pořadí',
  discussion_key_ranking: 'Key pořadí',
  common_brewery: 'Pivovar',
  common_trial_identifier: 'ID Testu',
  common_key_score: 'Key skóre',
  round_report: 'Export do Excelu',
  common_comments: 'Komentáře',
  message_you_cannot_delete_all_comments:
    'Při úpravě hlasování nemůžete smazat všechny komentáře',
  common_message_empty_profile: 'Název profilu není povolen prázdný',
  common_message_out_score_range: 'skóre mimo rozsah',
  tasting_need_select_score: 'Nejprve musíte nastavit skóre!',
  common_category_name: 'Kategorie',
  categories_new_category: 'Nová kategorie',
  categories_remove_category: 'Opravdu chcete odstranit kategorii',
  categories_list_categories: 'Kategorie',
  categories_list_all_categories: 'Všechny kategorie',
  common_category_sample_name: 'Vzorek',
  categories_new_category_sample: 'Nový vzorek',
  categories_remove_category_sample: 'Opravdu chcete odstranit vzorek',
  categories_list_categories_samples: 'Vzorky',
  tasting_help: 'Nápověda k degustaci',
  common_back: 'Zpět',
  common_next: 'Další',
  common_sample_id: 'ID vzorku',
  common_sample_date: 'Datum vzorku',
  common_duplicate: 'Duplikovat',
  tasting_raw_materials: 'Suroviny',
  tasting_example_sensory_risk:
    'Příklad hlavního senzorického rizika: neshoda s očekávanou chutí',
  tasting_procedure: 'Proces',
  tasting_sample_preparation: 'Příprava vzorku',
  tasting_help_comments: 'Poznámky',
  common_collection_point: 'Místo odběru',
  common_collection_date: 'Datum odběru',
  common_brew_number: 'Čísla várek/číslo MES',
  common_need_explain_nok: 'Nelze mít vzorky NOK bez komentáře!',
  common_percent_ok: 'Procento OK',
  common_show_all: 'Zobrazit vše',
  common_import: 'Import',
  common_download_template: 'Stáhnout šablonu',
  sample_import_message: 'xlsx',
  common_template: 'Šablona',
  common_yes: 'Yes',
  common_no: 'No',
  common_need_volte_first: 'Vzory nelze komentovat bez předchozího hlasování!',
  common_name_template: 'Název šablony',
  interaction_log: 'Záznam o interakci',
  common_message_zone_empty: 'Zóna nesmí být prázdná',
  common_message_country_empty: 'Země nesmí být  prázdná',
  common_message_site_empty: 'Místo nesmí být prázdné',
  user_new_profile_save: 'Vytvořen nový profil',
  common_in_privileges: 'v oprávněních',
  common_download_files: 'Stáhnout soubory',
  pdf_viewer: 'Prohlížeš PDF',
  common_all: 'Všechny',
  common_continue: 'Pokračovat',
  common_minimum_score: 'Min',
  common_maximum_score: 'Max',
  common_average_score: 'Průměrná hodnota',
  file_upload_fail:
    'Nepodařilo se nahrát soubor, došlo k následujícím chybám: ',
  file_upload_success_with_error:
    'Nahrávání proběhlo úspěšně s výjimkou následujících chyb: ',
  missing_fields: 'chybějící pole',
  column_error: 'chyba sloupce',
  common_line: 'Řádek',
  file_no_samples: 'V souboru nebyly nalezeny žádné vzorky!',
  file_success_upload: 'Úspěšné nahrání souboru!',
  file_duplicated_kpi_project: 'Duplikovaný KPI nebo projekt',
  round_schedule_maximum_length: 'Maximální délka názvu kola je 40 znaků.',
  sample_malt_varieties: 'Odrůda sladu',
  error_sample_already_in_round: 'Vzorek se již v kole nachází',
  malt_varieties_has_no_english_name:
    'Musíte mít alespoň jeden název sladové odrůdy.',
  malt_varieties_duplicate: 'Upozornění: Existuje odrůda sladu s tímto názvem',
  malt_varieties_remove: 'Odstranit odrůdu',
  malt_varieties_new: 'Nová odrůda',
  reports_no_dates: 'Datum zahájení a datum ukončení jsou povinné!',
  reports_date_range_message: 'Rozsah dat nesmí být větší než 1 rok!',
  round_save_select_site: 'Pro uložení kola je nutné vybrat lokalitu',
  common_error: 'Ups! Něco se pokazilo, zkuste to prosím znovu',
  common_horizontal: 'Horizontální rozložení',
  common_unit: 'Jednotka',
  common_supplier: 'Dodavatel',
  common_flavor: 'Příchuť',
  concentration_of_the_base_beer: 'Koncentrace základního piva',
  threshold_aml_method: 'Metoda AML (vzestupná metoda limitů)',
  threshold_component_concentration: 'Koncentrace složky',
  threshold_sample_different_help: 'Který vzorek se liší?',
  threshold_each_set_random_order: 'V každé sadě v náhodném pořadí.',
  threshold_select_base_beer: 'Vyberte základní pivo',
  common_unity: 'Jednota',
  common_finish_session: 'Dokončit ochutnávku',
  review_submit_threshold: 'Jste si jisti, že chcete uložit?',
  threshold_coder_wrong: 'Zadaný kód je špatný. Zadejte prosím opravený kód.',
  threshold_didnt_enter_the_code: 'Zadejte prosím svůj kód',
  code: 'Kód',
  common_batch_code: 'Kód šarže',
  threshold_samples_required: 'Základní pivo a Příchuť jsou povinné',
  threshold_information_helper_sample:
    'Chcete-li uložit informace o prahovém vzorku, klikněte na tlačítko Uložit, aby se změny zachovaly',
  code_digits: 'Zadejte třímístný kód, který vám byl přidělen',
  threshold_sample_spiked_with:
    'V každé sadě vyberte vzorek, do kterého bylo něco přidáno (spiked sample) ',
  threshold_code_already_tasted:
    'Tento kód již ochutnal jiný uživatel. Zadejte jiný kód.',
  common_kpi_brands: 'KPI značky',
  common_year: 'Rok',
  common_new_kpi_brand: 'Nová KPI značka',
  common_action: 'Akce',
  common_comment_by_category: 'Komentáře podle kategorie',
  common_alphabetic_comment: 'Abecední komentáře',
  common_name_pts_code: 'Kód PTS',
  common_name_pts_code_placeholder: 'Kód',
  common_name_process_item: 'Zpracovávaná položka',
  common_name_process_item_placeholder: 'Položka',
  common_global_brands: 'Globální značky',
  common_global_brand: 'Globální značka',
  common_target_global_brand: 'Cíl pro globální značku',
  common_focus_brand: 'Vybraná značka',
  common_target_focus_brand: 'Cílová hodnota pro vybranou značku',
  common_strategic_brand: 'Strategická značka',
  common_target_strategic_brand: 'Cíl pro strategickou značku',
  common_maximun_sites: 'Nejvyšší počet k porovnání je',
  common_state_reason_rejecting: 'Uveďte důvod proč to oddělení odmítlo',
  common_rejected_note: 'Odmítnutá poznámka',
  report_list_report_kpi: 'Zprávy KPI',
  report_list_report_taste: 'Degustační zprávy',
  select_brand_to_filter: 'Vyberte globální značku!',
  select_year_to_filter: 'Vyberte rok!',
  kpi_only_numbers_warning: 'Akceptujte pouze čísla na cílových hodnotách',
  kpi_max_number_range_warning: 'Vaše cílová značka musí být v rozmezí 1 až 9',
  kpi_already_selected_brand_warning:
    'Tuto značku máte vybranou, vyberte prosím jinou značku',
  kpi_no_kpi_informed_warning:
    'Nebyla zadána žádná hodnota KPI. Doplňte prosím tuto hodnotu.',
  kpi_select_year_warning: 'Vyberte rok',
  kpi_select_site_warning: 'Vyberte lokalitu',
  kpi_existing_configuration_warning:
    'Máte hodnoty kpi ze stejného místa a roku.',
  kpi_target_empty_warning: 'Cíl jedné z vašich značek je prázdný',
  kpi_saved_successfully: 'Vaše hodnoty byly úspěšně uloženy!',
  kpi_submitted_successfully: 'Vaše hodnoty byly úspěšně odeslány!',
  kpi_max_decimals_warning:
    'Vaše cílová hodnota musí být uvedena s přesností na 3 desetinná místa',
  kpi_count_as_kpi_sample: 'Počítejte jako KPI',
  samples_uncheck_kpi_sample_tooltip:
    'Zrušte zaškrtnutí tohoto políčka, pokud nechcete, aby se tento vzorek započítával do KPI',
  reports_mandatory_zone_filter: 'Vyberte alespoň jednu zónu',
  common_approve_reject: 'Schválit/odmítnout',
  common_approve: 'Schválit',
  submission_type: 'Typ podání',
  custom_scale_title: 'Vlastní měřítko',
  custom_scale_grid_header_score: 'Skóre',
  custom_scale_grid_header_ageind_scale: 'Stupnice nuceného stárnutí',
  the_value_cannot_be_null: 'Hodnota nesmí být nulová!',
  unique_id: 'Jedinečné ID',
  report_filter_rounds_description:
    'Pro načtení možností kol je nutné vybrat povinná pole.',
  reports_mandatory_test_type: 'Vyberte typ testu.',
  round_reports_month_warning:
    'Období mezi datem zahájení a datem ukončení musí být maximálně 6 měsíců.',
  zone_can_not_empty: 'Oblast nesmí být prázdná!',
  error_loading_data:
    'Chyba při načítání dat: Zkontrolujte připojení a znovu načtěte data.',
  flavor_identification: 'Identifikace příchutě',
  beer_batch: 'Šarže piva',
  list_of_beers: 'Seznam piv',
  random: 'Náhodné',
  preview: 'Náhled místa',
  select_the_flavor: 'Výběr příchutě',
  flavor_threshold: 'Práh příchutě',
  input_digital_number: 'Zadejte digitální číslo...',
  concentration: 'Koncentrace',
  flavor_order: 'Pořadí příchutí:',
  last: 'Poslední',
  tasting: 'Degustace',
  confirmation: 'Potvrzení',
  do_you_confirm_the_evaluation_of_this_sample:
    'Potvrzujete hodnocení tohoto vzorku?',
  confirm: 'Potvrzuji',
  evaluate: 'Hodnotit',
  correct_answer: 'Správná odpověď',
  threshold: 'Prahová hodnota',
  let_a_comment_about_the_tasting: 'Napiště komentář k degustaci',
  send: 'Odeslat',
  right_now: 'Právě teď',
  minute_ago: 'před m',
  hour_ago: 'před h',
  day_before: 'před d',
  year_ago: 'před rokem',
  add_a_flavor: 'Přidat příchuť',
  soft_drinks: 'Nealkoholické nápoje',
  soft_drinks_brand: 'Značka nealkoholických nápojů',
  soft_drinks_brand_target: 'Cílová hodnota pro značka nealkoholických nápojů ',
  column_options: 'Možnosti sloupců',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Vyberte, které sloupce chcete zobrazit.',
  countdown: 'Odpočítávání',
  less_than_five_minutes:
    'Tato degustace skončí za 5 minut, pokud potřebujete pokračovat, kontaktujte správce, aby posunul datum ukončení.',
  the_countdown_to_the_evaluation_has_ended:
    'Nelze vstoupit, protože odpočítávání této degustační relace skončilo',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Odpočítávání, kolik času máte na dokončení ochutnávky vzorků',
  only_six_flavors_can_be_added_at_most: 'Lze přidat maximálně 6 příchutí!',
  concentration_of_the_flavor: 'Koncentrace příchutě',
  input_value: 'Zadejte prosím',
  Concentration_of_the_flavor_is_required: 'Koncentrace příchutě je vyžadována',
  previous_round: 'Předchozí kolo',
  next_round: 'Další kolo',
  the_end_time_is_greater_than_the_current_time:
    'Čas ukončení musí být větší než aktuální čas, pokud vyberete stejný den jako aktuální den, vyberte nejprve hodinu a minutu!',
  check_the_answer_at_the_end_of_the_countdown:
    'Pro kontrolu výsledku vyčkejte, až odpočítávání klesne na nulu.',
  incremental_step: 'Navýšení',
  set3_concentration: 'Koncentrace Sady 3',
  concentration_increments_between_levels:
    'Přírůstky koncentrace mezi jednotlivými úrovněmi',
  incremental_set3_concentration_required:
    'Je požadováno "Navýšení" a Set3 Concentration.',
  sprint: 'Sprintu',
  sprint_number_greater_than_zero: 'Číslo sprintu musí být větší než 0',
  sprint_notes_at_least_one_note:
    'Poznámky ke sprintu musí mít alespoň jednu poznámku',
  sprint_patch_notes_success_update:
    'Poznámky k opravám sprintu byly úspěšně aktualizovány.',
  sprint_patch_notes_error_update: 'Poznámky nebylo možné uložit.',
  update_sprint_patch_notes: 'Aktualizovat poznámky k opravám Sprinut',
  sprint_number: 'Číslo sprintu',
  sprint_patch_notes: 'Poznámky k opravám Sprintu',
  note: 'Poznámka',
  select_all: 'Vybrat vše',
  please_add_samples_first: 'Nejprve přidejte vzorky',
  reverse: 'Reverse',
  score_scale: 'Stupnice Ab InBev',
  description: 'Popis',
  aditional_criteria: 'Dodatečná kritéria',
  new_test: 'Nový test',
  triangle_test: 'Trojúhelníkový test',
  triangle_test_name: 'Název trojúhelníkového testu',
  you_can_not_set_three_identical_samples: 'Nelze nastavit 3 identické vzorky',
  its_mandatory_to_input_test_name: 'Je povinné zadat Název testu.',
  its_mandatory_to_input_sample_information:
    'Je povinné zadat informace o vzorku.',
  attention: 'Pozor',
  test_number: 'Číslo testu',
  you_selected_the: 'Vybrali jste',
  next_sample: 'Další vzorek',
  remove_triangle_test: 'Odstranit trojúhelníkový test',
  test: 'Test',
  confirm_remove: 'Potvrzujete odstranění tohoto testu?',
  you_can_only_select_up_to_five: 'Můžete vybrat pouze 5 druhů.',
  reports_mandatory_zone_country_site_filter:
    'Zóna, země, lokalita jsou povinné!',
  reports_mandatory_brand_filter: 'Vyberte alespoň jednu značku',
  download_label: 'Stáhnout štítek',
  reports_by_producing_site: 'Přehledy podle místa produkce',
  standard: 'Standardní',
  input_test: 'Vstupní test',
  detecting: 'Detekce···',
  connected: 'Připojeno',
  current_taster: 'Aktuální degustátor',
  alcohol_concentration: 'Koncentrace alkoholu',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Výsledek testu na alkohol překračuje normu. Zopakujte prosím test později!',
  alcohol_test_qualified: 'Kvalifikovaný test na alkohol',
  configuration: 'Konfigurace',
  input_result: 'Zadání výsledku',
  maximum_alcohol_concentration: 'Překročení koncentrace alkoholu',
  breathalyzer: 'Dechová zkouška',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Zadejte kladné číslo s přesností na {{field}} desetinných míst.',
  you_must_input: 'Musíte zadat',
  driving: 'DrivingŘízení',
  test_time: 'Čas testu',
  input_type: 'Zadejte typ',
  list_breathalyzer: 'Alkohol tester',
  Manual: 'Manuální',
  Breathalyzer: 'Alkohol tester',
  you_do_not_have_privilege_to_export_this_report:
    'Nemáte oprávnění exportovat tuto zprávu',
  competitor_beer: 'Konkurenční pivo',
  split_round: 'Rozdělené kolo',
  reset: 'Resetovat',
  how_many_rounds_splitting_do_you_want_to_make: 'Kolik chcete rozdělit kol',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Nelze rozdělit kolo, které již bylo rozděleno nebo které právě probíhá',
  hide_sample_details: 'Skrýt podrobnosti o vzorku',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Údaje budou degustátorům skryty: Pivovar, datum výroby, značka, odrůda sladu a ID vzorku',
  data_will_be_hidden_for_ok_nok:
    'Všechny relevantní údaje budou degustátorům skryty',
  you_can_not_add_duplicate_attributes: 'Nelze přidávat duplicitní atributy.',
  menu: 'MENU',
  link_breathalyzer: 'Připojeno',
  not_link_breathalyzer: 'Nepřipojeno',
  breathalyzer_to_link: 'Připojit Alkohol tester',
  breathalyzer_can_not_to_link_for_this_browser:
    'Funkce Alkohol testeru je vyvinuta tak, aby fungovala v prohlížeči Google Chrome a používala zařízení PC.',
  reopen: 'Znovu otevřít',
  round_reopen_message: 'Jste si jisti, že chcete znovu otevřít Round',
  common_production_line: 'Výrobní linka',
  common_packaged_beer_data_code: 'Datový kód baleného piva',
  common_masking_process_for_bottles: 'Proces maskování lahví',
  common_str_number_days_over_aged: 'Počet dní staření',
  common_over_aged_beer: 'Přezrálé pivo',
  sample_successfully_swapped: 'Vzorek byl úspěšně vyměněn.',
  scoring: 'Bodování',
  configure_available_flavors: 'Konfigurace dostupných příchutí',
  process_check: 'Kontrola procesu',
  new_process_check: 'Kontrola nového procesu',
  edit_process_check: 'Upravit kontrolu procesu',
  remove_process_check: 'Odstranění kontroly procesu',
  common_package: 'Balení',
  common_department: 'Oddělení',
  common_shift: 'Směna',
  common_tank: 'Tank',
  common_oknok: 'OKNOK',
  common_remark: 'Poznámka',
  show_all_process_check: 'Zobrazit všechny kontroly procesů',
  show_all_process_check_help_circle:
    'Zobrazení kontroly procesu vytvořené za šest měsíců ode dneška. Zaškrtněte toto políčko, pokud chcete zobrazit také kontrolu procesu vytvořenou během jednoho roku ode dneška',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'Datum vzorku nesmí být větší než aktuální čas!',
  common_job_position: 'Pracovní pozice',
  decimal_sep: 'Desetinná čárka',
  is_swapped_to: 'Vyměnit za',
  review_warning_comment_title: 'Máte neokomentované vzorky!',
  continuous_typing: 'Průběžné zadávání',
  continuous_typing_tips:
    'Pokračujte v psaní „Atribut“ po výběru/zadání „Intenzity“.',
  BSSI_Reports: 'Reporty BSSI',
  data_analysis_sip: 'Analýza dat - SIP',
  select_the_color: 'Vyberte barvu',
  Color: 'Barva',
  download_document: 'Stáhnout dokumenty',
  Month_of_Discontinued_Production: 'Měsíc přerušené výroby',
  Suspension_List: 'Seznam pozastavení',
  New_Suspension: 'Nové pozastavení',
  new_suspension_page: 'Nové pozastavení',
  edit_suspension_page: 'Upravit pozastavení',
  Brand_name: 'Název značky',
  List_Brewery_Production_Suspension: 'Seznam pozastavení výroby pivovaru',
  Upload_successfully: 'Soubor byl úspěšně nahrán!',
  training_materials: 'Kliknutím zobrazíte školicí materiály.',
  Remove_Suspension: 'Odstranit pozastavení: ',
  Add_Absence_Reason: 'Přidat důvod absence',
  Reason: 'Důvod',
  You_have_duplicate_absence_dates: 'Máte duplicitní data absence',
  Remove_Absence: 'Odebrat absenci: ',
  Absence_Title: 'Vyberte prosím čas a důvod vaší absence na degustaci',
  Shipping_Site: 'Místo odeslání',
  shipping_received: 'Přijatá zásilka',
  receive_sample_alert: 'Jste si jisti, že jste vzorek obdrželi?',
  receive_uncheck_alert: 'Jste si jisti, že chcete zrušit zaškrtnutí?',
  all_received: 'Vše přijato',
  not_all_received: 'Ne všechno přijato',
  not_for_shipping: 'Není určeno k odeslání',
  receive_hint: 'Vyberte všechna místa, která obdrží tento vzorek',
  absence_reason: 'Důvod absence',
  Page1: 'Stránka {{field}}',
  Search: 'Vyhledávání',
  page_total: 'Celkem',
  start_date: 'Datum zahájení',
  end_date: 'Datum ukončení',
  ok_for_all: 'OK pro všechny',
  last_days: 'Posledních {{field}} dnů',
  today: 'Dnes',
  its_mandatory_to_select_sample_A_and_sample_B:
    'Je nutné vybrat vzorek A a vzorek B.',
  its_mandatory_to_select_sample_A: 'Je povinné vybrat vzorek A.',
  its_mandatory_to_select_sample_B: 'Je povinné vybrat vzorek B.',
  triangle_comment: 'Komentář',
  select_sample: 'Vyberte vzorek',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Filtr Datum vzorku,posledních 7 dní ve výchozím nastavení , export do Excelu na základě této filtrované doby trvání.',
  select_all_flavor: 'Vybrat vše',
  deselect_all: 'Zrušit výběr všech',
  email_distribution: 'Distribuce e-mailů',
  recipients: 'Komu',
  cc_recipients: 'Kopie',
  new_email_distribution: 'Nová distribuce e-mailů',
  edit_email_distribution: 'Upravit distribuce e-mailů',
  distribution_type: 'Typ distribuce',
  the_score_entered_is_invalid:
    'Zadané skóre je neplatné. Podívejte se prosím na bodovou stupnici a zadejte skóre znovu.',
  service_now:
    'Klepnutím na tlačítko vytvoříte lístek incidentu, abyste nahlásili problém a získali pomoc.',
  service_now_tips:
    'Ahoj, pokud narazíte na nějaký problém nebo chcete požádat o podporu, můžete kliknout sem a nahlásit jej přímo v ServiceNow, náš tým podpory se s vámi co nejdříve spojí.',
  done: 'Hotovo',
  changes_you_made_may_not_be_saved:
    'Provedené změny se nemusí uložit. Chcete pokračovat a přijít o změny?',
  cannel_auto_save_tips:
    'Aktuální výkon degustační stránky je vylepšen, nezapomeňte prosím kliknout na tlačítko Dokončit vzorek pro uložení a odeslání výsledku degustace.',
  list_email_page_tips:
    'Toto je návod, jak nastavit správný vzorek KPI, abyste se ujistili, že měsíční zpráva zóny odráží přesnou analýzu pro vaši zónu MCS degustace',
  list_email_page_helps:
    'Návod, jak nastavit vzorek KPI, aby ukázal přesné údaje v měsíční zprávě Zóny (Zone Monthly Report)',
  kpi_instruction: 'Instrukce pro KPI',
  group_no_recipients_message: 'Je zapotřebí alespoň jeden příjemce.',
  the_current_distribution_type_already_exists:
    'Aktuální typ distribuce již existuje, znovu vyberte typ nebo vraťte seznam.',
  distribution_type_message_empty: 'Typ distribuce není povolen prázdný',
  add_samples: 'Přidejte vzorek(y) do kola',
  copy_this_sample: 'Duplikovat vzorek',
  remove_this_sample: 'Odstranění vzorku',
  multiple_selection_tips: `Pro stejný typ vzorku můžete vybrat několik vzorků najednou. Vyzkoušejte to kliknutím na ‘+’ a ‘-’.`,
  all_add_once_tips: `Kliknutím na tlačítko ‘Vybrat vše’ automaticky přidáte 1 vzorek ke každému vybranému typu vzorku.`,
  copy_confirm_tip: 'Jste si jisti, že chcete vzorek zkopírovat?',
  user_non_abi_user: 'Nový dočasný uživatel',
  language_of_email_invitation: 'Jazyk e-mailové výzvy',
  add_non_abi_users_to_round:
    'Dočasný uživatel obdrží e-mailovou pozvánku s odkazem na účast na konkrétní degustaci.',
  change_non_abi_users_to_round:
    'Dočasný degustátor, kterého jste pozvali, obdrží e-mailovou pozvánku s odkazem na účast na této degustaci.',
  guest: 'Host',
  email_to_all: 'E-mail všem',
  send_email: 'Odeslání pozvánky e-mailem',
  temporary_non_abi_taster: 'Dočasný degustátor',
  user_type: 'Typ uživatele',
  email_invitation_delivery_request_sent: 'Hromadná pozvánka byla odeslána.',
  incorrect_e_mail_formatting: 'Nesprávné formátování e-mailu',
  all_tasters: 'Všichni degustátoři',
  key_tasters: 'Klíčoví degustátoři',
  non_key_tasters: 'Neklíčoví degustátory',
  temporary: 'Dočasný uživatel',
  maximum_number_of_samples_is: 'Maximální počet vzorků je',
  sample_added: 'Přidané vzorky',
  click_to_view_scale_of_flavour_intensities:
    'Kliknutím na odkaz zobrazíte podrobné stupnice intenzity',
  list_next_week_plan: 'Plánování týdenních relací',
  common_time_zone: 'Časové pásmo',
  common_last_response_time: 'Čas poslední reakce',
  new_week_plan: 'Degustace nového týdne',
  invited_week: 'Týden, kdy jste byli pozváni',
  email_language: 'Jazyk e-mailu',
  save_and_send_email: 'Uložit a odeslat e-mail',
  common_start_time: 'Čas zahájení',
  common_end_time: 'Čas ukončení',
  session_name_email_subject: 'Název relace / Předmět e-mailu',
  location: 'Místo',
  calendar_confirm: 'Potvrzení kalendáře',
  edit_week_plan: 'Upravit týdenní sezení',
  common_session: 'Degustace',
  round_remove_plan: 'Jste si jisti, že chcete plán odstranit',
  email_response: 'E-mailová odpověď',
  are_you_confirming_to_send_the_invitation_email:
    'Potvrzujete odeslání e-mailu s pozvánkou',
  view_week_plan: 'Zobrazit týdenní degustace',
  are_you_confirming_to_send_the_confirmation_email:
    'Potvrzujete odeslání potvrzovacího e-mailu?',
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation:
    'Každých 10 minut se aktualizuje e-mailová schránka s odpověďmi pro plánované degustace.',
  documents_upload_tips: 'Nahrávání souborů podporuje pouze {{field}}',
  ignore_submit: 'Ignorovat&Odeslat',
  if_count_as_kpi:
    'Pokud je to nutné pro výpočet BSSI a GBSI, nezapomeňte u níže uvedených vzorových klíčových ukazatelů výkonnosti  (KPI) ‘Počítat jako klíčový ukazatel výkonnosti (KPI)’',
  confirmed_count: 'Potvrzený počet',
  accepted: 'Přijato',
  declined: 'Odmítnuto',
  no_response: 'Bez odpovědi',
  apply_to_all_sessions: 'Použít pro všechny degustace',
  reminder_time: 'Čas připomenutí',
  are_you_sure_you_want_to_change_the_week_of_invitation:
    'Jste si jisti, že chcete odstranit vybraný čas zahájení/ukončení degustace a změnit na jiný termín.',
  apply_to_all_sessions_location_tasters:
    'Použít ‘Místo’ a ‘Degustátory’ na následující sezení',
  upload_tasters: 'Nahrát ochutnávky',
  mcs_tasting: 'Ochutnávka MCS',
  mcs_tasting_tips:
    'Pokud toto kolo obsahuje vzorky MCS/KPI, systém použije související kód KPI podle značky vzorku.',
  round_samples_shuffle_confirm: 'Určitě chcete zamíchat sady?',
  all_count_as_kpi: 'Vše se počítá jako KPI',
  msc_tasting_tour_mask:
    'Systém automaticky identifikuje související KPI kód na základě spočítaného vzorku se správným KPI měsícem, takže nemusíte rozdělovat různé značky v jednom kole.',
  competition: 'Soutěž',
  copy_link: 'Kopírovat odkaz',
  open_link: 'Otevřít odkaz',
  common_download_img: 'Stáhnout IMG',
  please_select_taster: 'Vyberte si prosím své jméno',
  qr_code: 'QR kód',
  global_id: 'Globální ID',
  user_principal_name: 'Jméno hlavního uživatele',
  user_status: 'Stav uživatele',
  termination_date: 'Datum ukončení',
  function_level: 'Úroveň funkce',
  last_update_time: 'Čas poslední aktualizace',
  system_related: 'Související systém',
  workday_related: 'WorkDay Související',
  employee_id: 'ID zaměstnance',
  zitec_project_name: 'Název projektu',
  applicant: 'Žadatel',
  applicant_date: 'Datum žádosti',
  test_method: 'Zkušební metoda',
  liquid_category: 'Kategorie kapalin',
  conditions: 'Conditions',
  time_points: 'Podmínky',
  execution_date: 'Datum provedení',
  execution_by_time_points: 'Vypočítané datum provedení podle časových bodů',
  invitation_start_time: 'Čas zahájení pozvánky',
  invitation_end_time: 'Čas ukončení pozvánky',
  invitation_reminder: 'Připomenutí pozvánky',
  application_form: 'Formulář žádosti',
  application_form_result: 'Výsledek formuláře žádosti',
  upload_result: 'Výsledek nahrávání',
  zitec_sessions: 'Zasedání ZITEC',
  zitec_id: 'ZITEC ID',
  new_zitec_session: 'Nové zasedání ZITEC',
  common_search_applicant: 'Hledat žadatele',
  history_record: 'Záznam o historii',
};

