import MainService from './main.service';

export default class SampleService {
  mainService = null;

  constructor() {
    this.mainService = new MainService();
    this.path = '/v1/admin';
  }

  listSamplesTypes = () => {
    return this.mainService.get(this.path + '/types');
  };

  listSamples = () => {
    return this.mainService.get(this.path + '/list_samples');
  };

  listSubmission = () => {
    return this.mainService.get(this.path + '/list_submission_types');
  };

  getSample = uuid_sample => {
    return this.mainService.get(this.path + `/get/sample/${uuid_sample}`);
  };

  listSamplePerformance = (uuid_sample, uuid_round) => {
    return this.mainService.post(`/v1/app/sample/performance`, {
      uuid_sample,
      uuid_round,
    });
  };

  listSamplesByServer = (body = null) => {
    return new Promise((resolve, reject) => {
      this.mainService.post(this.path + '/list_samples/pagination', body).then(
        res => {
          res.data.forEach(e => {
            e.arr_tasted = e.arr_tasted?.map(item => ({
              ...item,
              label: `${item.int_round}`,
              key: item.uuid_round,
              value: item.uuid_round,
              closed: item.bol_closed,
            }));
            e.dat_kpi = e.dat_kpi?.substring(0, 10); //Get rid of timestamp
          });
          resolve(res);
        },
        error => {
          //console.log(error);
          reject(error);
        },
      );
    });
  };

  listCategoriesSamples = () => {
    return this.mainService.get(this.path + '/list_categories/samples');
  };

  listAdminCategories = () => {
    return this.mainService.get(this.path + '/categories');
  };

  saveAdminCategories = params => {
    return this.mainService.post(this.path + '/categories', params);
  };

  deleteAdminCategories = uuid_category => {
    return this.mainService.delete(this.path + '/categories/' + uuid_category);
  };

  listAdminCategoriesSamples = () => {
    return this.mainService.get(this.path + '/categories/samples');
  };

  saveAdminCategoriesSamples = params => {
    return this.mainService.post(this.path + '/categories/samples', params);
  };

  deleteAdminCategoriesSamples = uuid_category => {
    return this.mainService.delete(
      this.path + '/categories/samples/' + uuid_category,
    );
  };

  listProducts = () => {
    return this.mainService.get(this.path + '/list_products');
  };

  listTemperatures = () => {
    return this.mainService.get(this.path + '/list_temperatures');
  };

  listStorageTimes = () => {
    return this.mainService.get(this.path + '/list_storage_time');
  };

  findSample = obj_sample => {
    return this.mainService.post(this.path + '/find_sample', obj_sample);
  };

  deleteSample = uuid_sample => {
    return this.mainService.delete(this.path + '/sample/' + uuid_sample);
  };

  updateSample = obj_sample => {
    return this.mainService.post(this.path + '/sample', obj_sample);
  };

  listRoundSample = () => {
    return this.mainService.post(this.path + '/list', {
      dat_last: '1991-04-01T15:52:47.000Z',
    });
  };

  updateBrewNumber = objBrewNumber => {
    return this.mainService.post(`${this.path}/brew_number`, objBrewNumber);
  };

  deleteBrewNumber = uuid_brew_number => {
    return this.mainService.delete(
      `${this.path}/brew_number/${uuid_brew_number}`,
    );
  };

  listBrewNumbers = (uuid_category_option, uuid_round, uuid_sample) => {
    return this.mainService.get(
      this.path +
        `/list_brew_number/${uuid_round}/${uuid_category_option}/${uuid_sample}`,
    );
  };

  listSampleKpi = (uuid_sample, uuid_site, uuid_brand, dat_kpi) => {
    return this.mainService.post(this.path + `/find_kpi`, {
      uuid_sample,
      uuid_site,
      uuid_brand,
      dat_kpi,
    });
  };

  findSampleProject = (
    uuid_sample,
    uuid_site,
    uuid_brand,
    str_name_project,
    str_trial_identifier,
  ) => {
    return this.mainService.post(this.path + `/find_sample_project`, {
      uuid_sample,
      uuid_site,
      uuid_brand,
      str_name_project,
      str_trial_identifier,
    });
  };

  isBreweryBrandKPI = (uuid_site, uuid_brand) => {
    return this.mainService.get(
      this.path + `/is_kpi_brewery_brand/${uuid_site}/${uuid_brand}`,
    );
  };

  getAllKpiRoundCodes = () => {
    return this.mainService.get(this.path + `/get_all_round_kpi_codes/`);
  };

  listRoutineWarehouse = () => {
    return this.mainService.get(this.path + `/list_routine_warehouse`);
  };

  listDepartment = () => {
    return this.mainService.get(this.path + `/list_department`);
  };

  listShift = () => {
    return this.mainService.get(this.path + `/list_shift`);
  };

  listProductionLine = () => {
    return this.mainService.get(this.path + `/list_production_line`);
  };

  listDecimalSymbol = () => {
    return this.mainService.get(this.path + `/list_decimal_symbol`);
  };

  shippingSitesReceived = receivedInfo => {
    return this.mainService.post(`${this.path}/sample_receive`, receivedInfo);
  };

  isBreweryBrandKPISample = (uuid_site, uuid_sample) => {
    return this.mainService.get(
      this.path + `/is_kpi_brewery_brand_sample/${uuid_site}/${uuid_sample}`,
    );
  };
}

