import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uploadButtonStyle from './uploadbutton.module.scss';
import { ArrowUp } from 'react-feather';
import { Loading } from '../';
import AppService from '../../app.service';
import moment from 'moment';

const UploadButton = props => {
  const [loading, setLoading] = useState(false);

  // services
  const appService = new AppService();

  const handleUpload = (name, file) => {
    let upload = true;
    if (props.allowExtension) {
      const ext = `.${name.split('.').pop().toLowerCase()}`;
      //console.log("Not allowed extensions: " + !props.allowExtension.includes(ext));
      if (!props.allowExtension.includes(ext)) {
        alert(`File type "${ext}" not allowed!`);
        upload = false;
        return;
      }
    }
    const str_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    if (upload) {
      appService
        .uploadFile({
          str_name: name,
          str_file: file,
          dat_nowTime: str_date,
        })
        .then(
          res => {
            if (res.str_file_name && res.str_file_name !== '') {
              const upload = {
                target: { value: res.str_file_name, name: props.name },
              };
              props.onUpload(upload);
            } else {
              const upload = { target: { value: '', name: '' } };
              window.alert(res.message || res);
              props.onUpload(upload);
            }
            setLoading(false);
          },
          error => {
            //console.log(error);
          },
        );
    }
  };

  const handleChangeFile = evt => {
    let files = evt.target.files;

    for (var i = 0; i < files.length; i++) {
      const reader = new FileReader();
      const file = files.item(i);

      if (props.maxSize) {
        if (file.size <= props.maxSize) {
          reader.onload = () => {
            if (props.uploadFunc) {
              props.uploadFunc(file.name, file);
            } else {
              handleUpload(file.name, reader.result);
            }
          };

          if (file) {
            reader.readAsDataURL(file);
          }
        } else {
          alert(`The maximum file size must be 5mb. (${file.name})`);
        }
      } else {
        reader.onload = () => {
          if (props.uploadFunc) {
            props.uploadFunc(file.name, file);
          } else {
            handleUpload(file.name, reader.result);
          }
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
    evt.target.value = '';
  };

  const handleClick = evt => {
    setLoading(true);
    document.getElementById('file' + props.name).click();
    window.addEventListener('focus', handleUnClick);
  };

  const handleUnClick = evt => {
    setLoading(false);
    window.removeEventListener('focus', handleUnClick);
  };

  const handleAllowExtensions = () => {
    //THE DEFAULT EXT, OLD DEFINITIONS NO BREAKE THE CODE
    return props.allowExtension
      ? props.allowExtension.join(',')
      : 'image/*,.pdf,.zip,.rar,.xlsx';
  };

  return (
    <div>
      <input
        className={uploadButtonStyle.uploadInput}
        type="file"
        name="file"
        id={'file' + props.name}
        onChange={handleChangeFile}
        encType="multipart/form-data"
        accept={handleAllowExtensions()}
        multiple={props.multiple}
      />
      <button
        className={
          !props.disabled
            ? uploadButtonStyle.uploadButton
            : uploadButtonStyle.disabledButton
        }
        onClick={handleClick}
        style={{
          margin: props.margin ? props.margin : '0',
          backgroundColor: props.backgroundColor
        }}
        disabled={props.disabled ? props.disabled : false}
        readOnly={props.disabled ? props.disabled : false}
      >
        {props.label} <Loading loading={loading} />{' '}
        {!loading ? <ArrowUp size={20} /> : null}
      </button>
    </div>
  );
};

UploadButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  margin: PropTypes.string,
  uploadFunc: PropTypes.func,
  allowExtension: PropTypes.array,
  disabled: PropTypes.bool,
};

export default UploadButton;

