import React, { useState, useEffect } from 'react';
import editDegreeStyle from './editdegree.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  Button,
  CardColumn,
} from '../../../components';
import RoundService from '../../services/rounds/round.service';
import { useTranslation } from 'react-i18next';

const EditDegree = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [degree, setDegree] = useState({});

  // services
  const roundService = new RoundService();

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setDegree(props.location.state.values);
  };

  const handleChange = evt => {
    const value = evt.target.value;
    setDegree({
      ...degree,
      [evt.target.name]: value,
    });
  };

  const handleSave = () => {
    if (
      degree.str_name_en?.trim().length === 0 ||
      degree.str_name_en === undefined
    ) {
      window.alert(
        t('common_cannot_be_empty', {
          field: `${t('common_degree')} (English)`,
        }),
      );
      return;
    }
    roundService.updateDegree(degree).then(
      res => {
        if (res.type === 'success' && res.results) {
          history.goBack();
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  return (
    <div className={editDegreeStyle.pageContainer}>
      <Label label={t('degree_edit_degree')} bold neutral />

      <Card bordered>
        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_en"
            label={`${t('common_degree')} (English)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_en}
            onChange={handleChange}
          />
          <TextField
            name="str_name_pt"
            label={`${t('common_degree')} (Portuguese)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_pt}
            onChange={handleChange}
          />
          <TextField
            name="str_name_es"
            label={`${t('common_degree')} (Spanish)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_es}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_de"
            label={`${t('common_degree')} (German)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_de}
            onChange={handleChange}
          />
          <TextField
            name="str_name_fr"
            label={`${t('common_degree')} (French)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_fr}
            onChange={handleChange}
          />
          <TextField
            name="str_name_it"
            label={`${t('common_degree')} (Italian)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_it}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_nl"
            label={`${t('common_degree')} (Dutch)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_nl}
            onChange={handleChange}
          />
          <TextField
            name="str_name_ru"
            label={`${t('common_degree')} (Russian)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_ru}
            onChange={handleChange}
          />
          <TextField
            name="str_name_uk"
            label={`${t('common_degree')} (Ukranian)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_uk}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow flexWrap="wrap">
          <TextField
            name="str_name_zh"
            label={`${t('common_degree')} (Chinese)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_zh}
            onChange={handleChange}
          />
          <TextField
            name="str_name_kr"
            label={`${t('common_degree')} (Korean)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_kr}
            onChange={handleChange}
          />
          <TextField
            name="str_name_cz"
            label={`${t('common_degree')} (Czech)`}
            margin="10px 10px 0px 0px"
            value={degree.str_name_cz}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="35px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="35px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditDegree;
