import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GenericTable,
  Label,
  CustomColumns,
  Modal,
  UploadButton,
  Button,
  CardDocument,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import listZITECStyle from './listzitec.module.scss';
import RoundService from '../../services/rounds/round.service';
import AppService from '../../../app.service';
import UserService from '../../services/users/user.service';
import { AlertCircle, ArrowUpCircle, Trash } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import ReportService from '../../services/reports/report.service';
import configs from '../../../configs';
import _ from 'lodash';
import { Timeline } from 'antd';
import moment from 'moment';

const ListZITEC = props => {
  const history = useHistory();
  const { t } = useTranslation();
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  const appService = new AppService();
  const roundService = new RoundService();
  const userService = new UserService();
  const reportService = new ReportService();

  const [showCustomColumns, setShowCustomColumns] = useState(false);
  const [editPermission, setEditPermission] = useState([
    { str_name: 'common_id', bol_status: null },
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'zitec_project_name', bol_status: null },
    { str_name: 'applicant', bol_status: null },
    { str_name: 'applicant_date', bol_status: null },
    { str_name: 'liquid_category', bol_status: null },
    { str_name: 'test_method', bol_status: null },
    { str_name: 'conditions', bol_status: null },
    { str_name: 'time_points', bol_status: null },
    { str_name: 'execution_date', bol_status: null },
    { str_name: 'application_form', bol_status: null },
    { str_name: 'application_form_result', bol_status: null },
  ]);
  const [resultShow, setResultShow] = useState(false);
  const [result, setResult] = useState([]);
  const [rowData, setRowData] = useState({});
  let resultArr = [];
  const [delFiles, setDelFiles] = useState([]);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    appService.listPrivileges('ZITEC_LIST').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      error => {
        //console.log(error);
        setLoading(false);
      },
    );
    userService.listCustomColumns('zitec').then(res => {
      if (
        res.type === 'success' &&
        res.results[0].user_sp_se_column_options &&
        res.results[0].user_sp_se_column_options.length ===
          editPermission.length
      ) {
        setEditPermission(res.results[0].user_sp_se_column_options);
      }
    });
  };

  const handleNewZITEC = useCallback(() => {
    history.push({
      pathname: '/new_ZITEC',
      state: {
        privilege: privilege,
      },
    });
  }, [privilege]);

  const handleDownLoad = row => {
    if (row.str_app_from) {
      window.open(configs.file_api_url + '/v1/' + row.str_app_from);
    }
  };
  const handleRemoveZITEC = row => {
    return new Promise((resolve, reject) => {
      if (window.confirm(`${t('round_remove_round')} ${row.str_zitec_id} ?`)) {
        roundService.deleteZITEC(row.uuid_zitec).then(res => {
          if (res.type === 'success' && res.results) {
            resolve(true);
          } else {
            window.alert(res.message);
            resolve(true);
          }
        });
      } else {
        reject({ message: 'Cancel' });
      }
    });
  };
  const handleResult = (row, index) => {
    setResultShow(true);
    setRowData(row);
    const arr = row.arr_app_from_result ?? [];
    setResult(arr);
  };
  const handleSwichToTasteZITEC = () => {
    window.open('https://abinbev.eyeqsoft.com/eq/public/login.jsp', '_blank');
  };

  const filterHeaders = [
    {
      description: t('common_id'),
      field: 'str_zitec_id',
      cache: true,
      fieldCache: 'zitec.str_zitec_id',
    },
    {
      description: t('common_zone'),
      field: 'str_zone_name',
      cache: true,
      fieldCache: 'zitec.str_zone_name',
    },
    {
      description: t('zitec_project_name'),
      field: 'str_project_name',
      cache: true,
      fieldCache: 'zitec.str_project_name',
    },
    {
      description: t('applicant'),
      field: 'str_user_name',
      cache: true,
      fieldCache: 'zitec.str_user_name',
    },
    {
      description: t('applicant_date'),
      field: 'dat_app_date',
      width: '100px',
      fieldCache: 'zitec.dat_app_date',
      type: 'datetime',
      cache: true,
    },
    {
      description: t('liquid_category'),
      field: 'str_liquid_category',
      cache: true,
      fieldCache: 'zitec.str_liquid_category',
    },
    {
      description: t('test_method'),
      field: 'str_test_method',
      cache: true,
      fieldCache: 'zitec.str_test_method',
    },
    {
      description: t('conditions'),
      field: 'str_conditions',
      width: '120px',
      cache: true,
      fieldCache: 'zitec.str_conditions',
    },
    {
      description: t('time_points'),
      field: 'str_time_points',
      fieldCache: 'zitec.str_time_points',
      // notSearchable: true,
      cache: true,
    },
    {
      description: t('execution_date'),
      field: 'str_exec_date',
      cache: true,
      fieldCache: 'zitec.str_exec_date',
    },
    {
      description: t('application_form'),
      field: 'application_form',
      type: 'applicationForm',
      action: handleDownLoad,
      notSearchable: true,
    },
    {
      description: t('application_form_result'),
      field: 'application_form_result',
      type: 'applicationFormResult',
      action: handleResult,
      notSearchable: true,
    },
  ];
  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });
  const handleUpload = e => {
    const value = e.target.value;
    let arrDocs = [];
    let dataFilter = _.filter(resultArr, value);
    if (dataFilter.length === 0) {
      resultArr.push(value);
      arrDocs = _.union(result, resultArr);
    }
    setResult(arrDocs);
  };
  const handleRemove = e => {
    if (rowData.arr_app_from_result?.includes(e)) {
      setDelFiles([...delFiles, e]);
    }
    setResult(result.filter(item => item !== e));
  };
  const handleClose = () => {
    setResultShow(false);
    setResult([]);
    setDelFiles([]);
    resultArr = [];
  };
  const handleUploadResult = () => {
    const arr_add_files = result
      .filter(item => !delFiles?.includes(item))
      .filter(item => !rowData.arr_app_from_result?.includes(item));

    const data = {
      uuid_zitec: rowData.uuid_zitec,
      str_app_from_result: result,
      dat_oper_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      str_oper_type:
        arr_add_files.length > 0 && delFiles.length > 0
          ? '2'
          : delFiles.length > 0
          ? '1'
          : arr_add_files.length > 0
          ? '0'
          : '3',
      arr_add_files,
      arr_del_files: delFiles,
    };
    roundService.uploadResultZITEC(data).then(res => {
      if (res.type === 'success' && res.results) {
        handleClose();
        window.location.reload();
      } else {
        window.alert(res.message);
      }
    });
  };
  const renderHistory = row => {
    return (
      <Timeline.Item color="blue">
        <div className={listZITECStyle.historyItemTitle}>
          <p className={listZITECStyle.historyItemTitleName}>
            {row.str_oper_user ?? ''}
          </p>
          <p className={listZITECStyle.historyItemTitleTime}>
            {row.dat_oper_date
              ? moment(row.dat_oper_date).format('YYYY-MM-DD HH:mm:ss')
              : ''}
          </p>
        </div>
        <div className={listZITECStyle.historyItemContent}>
          {row.arr_add_files?.length > 0 && (
            <div className={listZITECStyle.historyItemContentText}>
              <ArrowUpCircle color="green" width={'12px'} />
              <p
                className={listZITECStyle.historyItemTitleContentAdd}
                data-tip={row.arr_add_files.join(', ')}
                data-for="historyItemTitleContentAdd"
              >
                {row.arr_add_files.join(', ')}
              </p>
              <ReactTooltip
                id="historyItemTitleContentAdd"
                effect="solid"
                place="top"
                type="warning"
                className={listZITECStyle.tooltip}
              />
            </div>
          )}
          {row.arr_del_files?.length > 0 && (
            <div className={listZITECStyle.historyItemContentText}>
              <Trash color="red" width={'12px'} />
              <p
                className={listZITECStyle.historyItemTitleContentRemove}
                data-tip={row.arr_del_files.join(', ')}
                data-for="historyItemTitleContentRemove"
              >
                {row.arr_del_files.join(', ')}
              </p>
              <ReactTooltip
                id="historyItemTitleContentRemove"
                effect="solid"
                place="top"
                type="warning"
                className={listZITECStyle.tooltip}
              />
            </div>
          )}
        </div>
      </Timeline.Item>
    );
  };
  return (
    <div className={listZITECStyle.pageContainer}>
      <Modal show={resultShow} className={listZITECStyle.modalContainer}>
        <div className={listZITECStyle.bodyContent}>
          <div className={listZITECStyle.documentList}>
            <div className={listZITECStyle.modalTitle}>
              {t('application_form_result')}
            </div>
            <div className={listZITECStyle.modalContent}>
              {result && result.length > 0 ? (
                <div className={listZITECStyle.CardDocument}>
                  {result.map(item => (
                    <CardDocument
                      name="str_zip_documents"
                      src={item}
                      notDownload={!rowData.bol_edit}
                      onRemove={
                        rowData.bol_edit
                          ? () => {
                              handleRemove(item);
                            }
                          : false
                      }
                      margin="10px 0px"
                      size={80}
                      width="100px"
                    />
                  ))}
                </div>
              ) : (
                <p style={{ textAlign: 'center' }}>{t('common_no_data')}</p>
              )}
              {rowData.bol_edit && (
                <div className={listZITECStyle.UploadButton}>
                  <UploadButton
                    name={`str_file`}
                    label={t('upload_result')}
                    margin="0px 10px 0px 0px"
                    allowExtension={[
                      '.jpeg',
                      '.jpg',
                      '.png',
                      '.pdf',
                      '.eml',
                      '.xls',
                      '.xlsx',
                    ]}
                    multiple={true}
                    backgroundColor="#3273f5"
                    onUpload={handleUpload}
                    maxSize={5242880}
                    allowExtensionShow
                  />
                  <AlertCircle
                    data-tip={
                      t('documents_upload_tips', {
                        field: '.jpeg, .jpg, .png, .pdf, .eml, .xls, .xlsx',
                      }) + '.'
                    }
                    className={listZITECStyle.helpCircle}
                    data-for="tip"
                  />
                  <ReactTooltip
                    id="tip"
                    effect="solid"
                    place="top"
                    type="warning"
                    className={listZITECStyle.tooltip}
                  />
                </div>
              )}
            </div>
            <div className={listZITECStyle.modalButton}>
              <Button
                label={t('group_close_modal')}
                neutral
                onClick={() => {
                  handleClose();
                }}
              />
              {rowData.bol_edit && (
                <Button
                  margin="0px 10px"
                  confirm
                  label={t('common_save')}
                  onClick={() => {
                    handleUploadResult();
                  }}
                />
              )}
            </div>
          </div>
          {rowData.arr_app_from_result_record?.length && (
            <div className={listZITECStyle.historyList}>
              <div className={listZITECStyle.modalTitle}>
                {t('history_record')}
              </div>
              <div className={listZITECStyle.historyBox}>
                <Timeline className={listZITECStyle.historyLine}>
                  {rowData.arr_app_from_result_record?.map(item =>
                    renderHistory(item),
                  )}
                </Timeline>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {showCustomColumns && (
        <CustomColumns
          title={t('column_options')}
          explain={t('select_which_of_the_columns_do_you_want_to_be_displayed')}
          round={'zitec'}
          arrHeader={headers}
          setShow={setShowCustomColumns}
          onChange={setEditPermission}
          values={editPermission}
        />
      )}
      <Label label={t('zitec_sessions')} bold neutral />
      <GenericTable
        setShowCustomColumns={setShowCustomColumns}
        originHeader={filterHeaders}
        arrHeader={headers}
        dataServer={roundService.getZITECList}
        searchable={true}
        exportService={reportService.exportZITEC}
        loading={loading}
        exportLabel={t('round_report')}
        newRegister={{
          onClick: handleNewZITEC,
          label: t('new_zitec_session'),
        }}
        onRemove={privilege.bol_access ? handleRemoveZITEC : null}
        onTaste={handleSwichToTasteZITEC}
      />
    </div>
  );
};

export default ListZITEC;

