import Samples from './Samples';
import Rounds from './Rounds';
import Users from './Users';
import Groups from './Groups';
import Brands from './Brands';
import Packages from './Packages';
import Reports from './Reports';
import Families from './Families';
import Comments from './Comments';
import Categories from './Categories';
import Degrees from './Degrees';
import Tasting from './Tasting';
import CategoriesOkNok from './CategoriesOkNok';
import CategoriesSamplesOkNok from './CategoriesSamplesOkNok';
import MaltVarieties from './MaltVarieties';
import KpiBrands from './KpiBrands';
import SprintPatchNotes from './SprintPatchNotes';
import BreathalyzerCapabilities from './BreathalyzerCapabilities';
import ListProcessCheck from './ProcessCheck';
import DataAnalysisSIP from './DataAnalysis';
import BreweryProductSuspension from './BreweryProductionSuspension';
import ZITEC from './ZITEC';
import EmailConfiguration from './EmailConfiguration';
import NextWeekPlan from './NextWeekPlan';

export default [
  Samples,
  Rounds,
  Groups,
  Reports,
  Families,
  Brands,
  Packages,
  Degrees,
  Categories,
  Comments,
  Users,
  Tasting,
  CategoriesOkNok,
  CategoriesSamplesOkNok,
  MaltVarieties,
  KpiBrands,
  SprintPatchNotes,
  BreathalyzerCapabilities,
  ListProcessCheck,
  DataAnalysisSIP,
  BreweryProductSuspension,
  ZITEC,
  EmailConfiguration,
  NextWeekPlan,
];
