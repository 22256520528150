import ListFlavorsStyle from './ListFlavors.module.scss';
import {
  CardRow,
  Button,
  CardColumn,
  ButtonList,
  DropdownField,
} from '../../components';
import configs from '../../configs';
import nullFlavor from '../../assets/Null_Flavor.png';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ListFlavorsModal = props => {
  const { t } = useTranslation();
  const [searchArr, setSearchArr] = useState(props.listFlavors);
  const [groupSelected, setGroupSelected] = useState(
    '5d34dd9b-04d2-4dcb-9521-766fa3c380f0',
  );
  const [searchValue, setSearchValue] = useState('');

  const handleSearchData = str => {
    setSearchValue(str);
  };
  const handleShowFlavor = () => {
    const filterArr = props.listFlavorGroups ? searchArr : props.listFlavors;
    return filterArr.filter(el => {
      const find = searchArr.find(v => v.uuid_flavor === el.uuid_flavor);
      el.bol_flavor_status = find
        ? find.bol_flavor_status
        : el.bol_flavor_status;
      return el.str_name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    });
  };
  const handleClickGroup = e => {
    setSearchValue('');
    let value = e.target.value;
    setGroupSelected(value);
    const filterGroups =
      value === '5d34dd9b-04d2-4dcb-9521-766fa3c380f0'
        ? props.listFlavors
        : props.listFlavors.filter(el => {
            const find = searchArr.find(v => v.uuid_flavor === el.uuid_flavor);
            el.bol_flavor_status = find
              ? find.bol_flavor_status
              : el.bol_flavor_status;
            return el.uuid_flavor_group === value;
          });
    setSearchArr(filterGroups);
  };
  return (
    <div className={ListFlavorsStyle.ListFlavorsModal}>
      <CardColumn className={ListFlavorsStyle.maskCardRow}>
        <div
          className={'iconfont ' + ListFlavorsStyle.off}
          onClick={() => props.closeModal()}
        >
          &#xe61d;
        </div>
        <CardRow className={ListFlavorsStyle.pc}>
          <CardColumn padding="0px 26px 0 0">
            {props.listFlavorGroups.length > 0 && (
              <ButtonList
                style={{
                  flexBasis: '120px',
                  width: '120px',
                  minWidth: '120px',
                  color: '#fff',
                  whiteSpace: 'nowrap',
                  backgroundColor: '#B0ADAD',
                  border: '1px solid #B0ADAD',
                }}
                list={props.listFlavorGroups}
                onClick={handleClickGroup}
                defaultSelected={groupSelected}
                currentSelected={groupSelected}
                arrow
              />
            )}
          </CardColumn>
        </CardRow>
        <CardRow
          transparent
          className={ListFlavorsStyle.searchDiv}
          itemAlign={'flex-end'}
        >
          <div style={{ width: '150px' }} className={ListFlavorsStyle.mobile}>
            <DropdownField
              style={{
                margin: '0 18px 0 6px',
              }}
              label={t('flavor_groups')}
              options={props.listFlavorGroups}
              valueField="value"
              labelField="label"
              value={groupSelected}
              onChange={handleClickGroup}
              placeholder={t('select_group')}
              bolOrder={false}
              hideRemoveButton
            />
          </div>
          <input
            className={ListFlavorsStyle.searchInput}
            placeholder={t('Search')}
            type="text"
            value={searchValue}
            onChange={e => {
              const str = e.target.value;
              handleSearchData(str);
            }}
          />
          {props.handleSelectAll && (
            <>
              <Button
                className={ListFlavorsStyle.selectButton}
                loading={props.loading}
                style={props.loading && { cursor: 'not-allowed' }}
                blue
                label={t('select_all_flavor')}
                onClick={() => {
                  const filterArr = searchArr.map(el => {
                    handleShowFlavor().forEach(v => {
                      if (el.uuid_flavor === v.uuid_flavor) {
                        el.bol_flavor_status = true;
                      }
                    });
                    return el;
                  });
                  props.handleSelectAll(
                    handleShowFlavor().map(el => {
                      el.bol_flavor_status = true;
                      return el;
                    }),
                    false,
                  );
                  setSearchArr(filterArr);
                }}
              />
              <Button
                className={ListFlavorsStyle.selectButton}
                loading={props.loading}
                style={props.loading && { cursor: 'not-allowed' }}
                confirm
                label={t('deselect_all')}
                onClick={() => {
                  const filterArr = searchArr.map(el => {
                    handleShowFlavor().forEach(v => {
                      if (el.uuid_flavor === v.uuid_flavor) {
                        el.bol_flavor_status = false;
                      }
                    });
                    return el;
                  });
                  props.handleSelectAll(
                    handleShowFlavor().map(el => {
                      el.bol_flavor_status = false;
                      return el;
                    }),
                    true,
                  );
                  setSearchArr(filterArr);
                }}
              />
            </>
          )}
        </CardRow>
        <ul className={ListFlavorsStyle.ul}>
          {handleShowFlavor()?.map((item, index) => {
            return (
              <li
                key={index}
                className={ListFlavorsStyle.flaovrlist}
                onClick={() => {
                  props.handleChange(item, index);
                  setSearchArr(prev =>
                    prev.map((el, i) => ({
                      ...el,
                      bol_flavor_status:
                        index === i
                          ? !el.bol_flavor_status
                          : el.bol_flavor_status,
                    })),
                  );
                }}
              >
                {props.checkbox && (
                  <div>
                    <input type="checkbox" checked={item.bol_flavor_status} />
                  </div>
                )}
                <img
                  src={
                    item.str_image
                      ? `${configs.file_api_url}/v1/flavors/${item.str_image}`
                      : nullFlavor
                  }
                  className={ListFlavorsStyle.img}
                />
                <div className={ListFlavorsStyle.flavorName}>
                  <p style={{ marginLeft: '15px', wordBreak: 'break-all' }}>
                    {item.str_name}
                  </p>
                  <p className={ListFlavorsStyle.GroupTag}>
                    {item.str_name_flavor_group}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </CardColumn>
    </div>
  );
};

export default ListFlavorsModal;

