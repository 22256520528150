import React, { Fragment, useEffect, useState } from 'react';
import style from './detailDiscussion.module.scss';
import {
  CardColumn,
  CardRow,
  ImageZoom,
  Label,
  ScoreScale,
  Spinner,
  Text,
  Button,
  PdfViewerModal,
  Accordion,
  FreshnessFiveScoreScale,
} from '../../../components/';
import { ArrowDown, ArrowUp, ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { useTasting } from '../../../contexts/Tasting';
import { useHistory } from 'react-router-dom';
import SamplePerformance from '../../../components/SamplePerformance';
import shadeForDetail from '../../../assets/shadeForDetail.png';

import SampleService from '../../services/samples/sample.service';
const sampleService = new SampleService();

const DetailDiscussion = () => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isOpenComments, setOpenComments] = useState(true);
  const [isOpenPerformance, setIsOpenPerformance] = useState(true);
  const [isOpenChart, setOpenChart] = useState(true);
  const [sample, setSample] = useState(null);
  const [graph, setGraph] = useState(null);
  const [isOpenSampleInfo, setIsOpenSampleInfo] = useState(false);
  const [performances, setPerformances] = useState([]);
  const { t } = useTranslation();
  const { taste } = useTasting();

  const history = useHistory();
  const [open, setOpenPdf] = useState(false);
  const showSampleDetails =
    history.location.state.showSampleDetails === false ? false : true;

  const handleClosePdf = () => {
    setOpenPdf(!open);
  };

  const handlePDF = () => {
    setOpenPdf(!open);
  };

  useEffect(() => {
    let sampleTemp = history.location.state;
    setSample(sampleTemp);

    handleLoadPerformance(sampleTemp.uuid_sample, sampleTemp.uuid_round);

    let values = [];
    sampleTemp.tasters.map(taster => {
      if (taster.arr_comments) {
        taster.arr_comments.map(comment => {
          if (values.map(info => info.comment).includes(comment.str_comment)) {
            values.forEach(info => {
              if (info.comment === comment.str_comment) info.value++;
            });
          } else {
            values.push({ comment: comment.str_comment, value: 1 });
          }
        });
      }
    });

    setGraph({
      options: {
        chart: { id: 'basic-bar' },
        fill: { colors: ['#F49B00'] },
        stroke: { colors: ['#F49B00'] },
        markers: { colors: ['#F49B00'], size: 5, hover: { size: 10 } },
        xaxis: {
          categories: values.map(data => data.comment),
          labels: {
            show: true,
            style: {
              colors: ['#000'],
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
        yaxis: {
          show: false,
        },
      },
      series: [
        {
          name: t('amount'),
          data: values.map(data => data.value),
        },
      ],
    });

    setLoading(false);
  }, []);

  async function handleLoadPerformance(uuid_sample, uuid_round) {
    try {
      const response = await sampleService.listSamplePerformance(
        uuid_sample,
        uuid_round,
      );
      setPerformances(response.results);
    } catch (error) {
      //console.log(error);
    }
  }

  const handleClickShowImage = show => {
    setOpen(!show);
  };

  const handleClickShowComments = show => {
    setOpenComments(!show);
  };

  const handleClickShowPerformance = show => {
    setIsOpenPerformance(!show);
  };

  const handleClickShowChart = show => {
    setOpenChart(!show);
  };

  const handleClickShowSampleInfo = show => {
    setIsOpenSampleInfo(!show);
  };

  const displayImage = () => {
    if (sample?.str_img_profile?.split('.').pop() !== 'pdf') {
      return <ImageZoom showClose={false} srcImage={sample.str_img_profile} />;
    } else {
      return (
        <PdfViewerModal
          label={`${t('common_brand')} PDF`}
          data={sample.str_img_profile}
          onClick={() => handlePDF()}
          closeForm={handleClosePdf}
          open={open}
          loading={false}
          downloadFileName={sample.str_img_profile}
          name={sample.str_img_profile}
        />
      );
    }
  };

  const displayChart = () => {
    return (
      <CardRow transparent padding="0" className={style.container}>
        <CardColumn
          padding="0"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Chart
            options={graph.options}
            series={graph.series}
            type="radar"
            width="700px"
          />
        </CardColumn>
      </CardRow>
    );
  };

  const displayComments = () => {
    return (
      <CardRow transparent padding="0" className={style.container}>
        <CardRow transparent padding="0">
          {sample.tasters?.map((info, i) => (
            <CardColumn key={i} className={style.cardColumn}>
              <CardRow>
                <CardColumn padding="0" style={{ marginBottom: '10px' }}>
                  <Text fontSize="14px" bold={true}>
                    {info.str_user_name ?? info.str_name} -{' '}
                    {info.score ?? info.flt_score}
                  </Text>
                </CardColumn>
              </CardRow>
              {info.arr_comments?.map((comment, i) => (
                <CardRow key={i}>
                  <CardColumn className={style.textComments}>
                    <Text>
                      {comment.str_degree ? comment.str_degree + ' - ' : ''}
                      {comment.str_comment}
                    </Text>
                  </CardColumn>
                </CardRow>
              ))}
            </CardColumn>
          ))}
        </CardRow>
      </CardRow>
    );
  };

  const displayPerformance = () => {
    return (
      <div className={style.performanceContainer}>
        {performances &&
          performances.map((round, index) => (
            <CardRow
              key={round.uuid_round}
              padding="15px"
              flex={1}
              className={style.roundInfo}
              style={{
                borderBottom:
                  index === performances.length - 1
                    ? 'none'
                    : '1px solid #E6E6E6',
              }}
            >
              <CardColumn
                padding="0"
                className={style.roundInfoLeftSide}
                flex={2}
              >
                <h3>{round.int_round}</h3>
                <CardRow padding="15px 0px" flex={1}>
                  <CardColumn padding="0" flex={1}>
                    <h4 className={style.scoreText}>
                      {t('common_average_score')}:
                    </h4>
                  </CardColumn>
                  <CardColumn padding="0">
                    <h3>
                      {round.flt_average_score
                        ? round.flt_average_score.toFixed(2)
                        : 0}
                    </h3>
                  </CardColumn>
                </CardRow>
                <CardRow padding="15px 0px" flex={1}>
                  <CardColumn padding="0" flex={1}>
                    <h4 className={style.scoreText}>
                      {t('common_average_key_score')}:
                    </h4>
                  </CardColumn>
                  <CardColumn padding="0">
                    <h3>
                      {round.flt_average_score_key
                        ? round.flt_average_score_key.toFixed(2)
                        : 0}
                    </h3>
                  </CardColumn>
                </CardRow>
              </CardColumn>
              <CardColumn padding="0" flex={5}>
                <CardRow transparent padding="0">
                  <SamplePerformance arr_comments={round.arr_comments} />
                </CardRow>
              </CardColumn>
            </CardRow>
          ))}
      </div>
    );
  };

  const displaySampleInfo = () => {
    return (
      <div>
        <CardRow transparent padding="0" className={style.containerSample}>
          <CardColumn style={{ minWidth: 200, flex: '0 !important' }}>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_position')}
              </Text>
              <Text className={style.textSamples}>
                {sample.int_order_app + 1}
              </Text>
            </CardRow>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('discussion_ranking')}
              </Text>
              <Text className={style.textSamples}>
                {sample.int_rank ? sample.int_rank : 'N/A'}
              </Text>
            </CardRow>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_batch')}
              </Text>
              {showSampleDetails ? (
                <Text className={style.textSamples}>
                  {sample.str_batch ? sample.str_batch : 'N/A'}
                </Text>
              ) : (
                <img src={shadeForDetail} alt="" className={style.detailImg} />
              )}
            </CardRow>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_score')}
              </Text>
              <Text className={style.textSamples}>
                {sample.flt_average_score
                  ? sample.flt_average_score.toFixed(2)
                  : 'N/A'}
              </Text>
            </CardRow>
          </CardColumn>
          <CardColumn style={{ minWidth: 200, flex: '0 !important' }}>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_brewery')}
              </Text>
              {showSampleDetails ? (
                <Text className={style.textSamples}>
                  {sample.str_site_name ? sample.str_site_name : 'N/A'}
                </Text>
              ) : (
                <img src={shadeForDetail} alt="" className={style.detailImg} />
              )}
            </CardRow>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_brand')}
              </Text>
              {showSampleDetails ? (
                <Text className={style.textSamples}>
                  {sample.str_brand_name ? sample.str_brand_name : 'N/A'}
                </Text>
              ) : (
                <img src={shadeForDetail} alt="" className={style.detailImg} />
              )}
            </CardRow>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_trial_identifier')}
              </Text>
              {showSampleDetails ? (
                <Text className={style.textSamples}>
                  {sample.str_trial_identifier
                    ? sample.str_trial_identifier
                    : 'N/A'}
                </Text>
              ) : (
                <img src={shadeForDetail} alt="" className={style.detailImg} />
              )}
            </CardRow>
            <CardRow transparent>
              <Text
                bold={true}
                style={{ marginTop: 5, width: 70, color: '#F49B00' }}
              >
                {t('common_key_score')}
              </Text>
              <Text className={style.textSamples}>
                {sample.flt_average_score_key
                  ? sample.flt_average_score_key.toFixed(2)
                  : 'N/A'}
              </Text>
            </CardRow>
          </CardColumn>
        </CardRow>
        <CardRow
          transparent
          style={{ backgroundColor: '#fff', paddingLeft: '10px' }}
        >
          <Text
            bold={true}
            style={{ marginTop: 5, width: 70, color: '#F49B00' }}
          >
            {t('common_notes')}
          </Text>
          <Text className={style.textSamples}>{sample.notes}</Text>
        </CardRow>
      </div>
    );
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <div style={{ padding: '10px 5px 5px 0px' }}>
          <CardRow transparent justifyContent="center">
            <CardColumn
              className={style.header}
              style={{ justifyContent: 'flex-start' }}
            >
              <Button
                label={''}
                onClick={() => handleBack()}
                style={{
                  borderRadius: '40px',
                  backgroundColor: '#F49B00',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: '40px',
                  height: '40px',
                  padding: '4px 0px 0px 0px',
                  marginLeft: '5px',
                }}
              >
                <ArrowLeft />
              </Button>
            </CardColumn>
          </CardRow>
          <CardRow transparent>
            <CardColumn className={style.containerComments}>
              <CardRow>
                <CardColumn
                  padding="15px"
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handleClickShowSampleInfo(isOpenSampleInfo)}
                >
                  <Label
                    style={{ cursor: 'pointer' }}
                    label={t('common_sample')}
                    bold
                  />
                  <span className={style.buttonShow}>
                    {isOpenSampleInfo ? <ArrowUp /> : <ArrowDown />}
                  </span>
                </CardColumn>
              </CardRow>
              {isOpenSampleInfo ? displaySampleInfo() : ''}
            </CardColumn>
          </CardRow>
          <CardRow transparent>
            <CardColumn className={style.containerComments}>
              <CardRow>
                <CardColumn
                  padding="15px"
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handleClickShowChart(isOpenChart)}
                >
                  <Label
                    style={{ cursor: 'pointer' }}
                    label={t('common_comments')}
                    bold
                  />
                  <span className={style.buttonShow}>
                    {isOpenChart ? <ArrowUp /> : <ArrowDown />}
                  </span>
                </CardColumn>
              </CardRow>
              {isOpenChart ? displayChart() : ''}
            </CardColumn>
          </CardRow>
          <CardRow transparent>
            <CardColumn className={style.containerComments}>
              <CardRow>
                <CardColumn
                  padding="15px"
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handleClickShowPerformance(isOpenPerformance)}
                >
                  <Label
                    style={{ cursor: 'pointer' }}
                    label={t('common_sample_performance')}
                    bold
                  />
                  <span className={style.buttonShow}>
                    {isOpenPerformance ? <ArrowUp /> : <ArrowDown />}
                  </span>
                </CardColumn>
              </CardRow>
              {isOpenPerformance ? displayPerformance() : ''}
            </CardColumn>
          </CardRow>
          {/* <CardRow transparent>
            <CardColumn className={style.containerComments}>
              <CardRow>
                <CardColumn
                  padding="15px"
                  style={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handleClickShowComments(isOpenComments)}
                >
                  <Label
                    style={{ cursor: 'pointer' }}
                    label={t('common_tasters') + ' ' + t('common_comments')}
                    bold
                  />
                  <span className={style.buttonShow}>
                    {isOpenComments ? <ArrowUp /> : <ArrowDown />}
                  </span>
                </CardColumn>
              </CardRow>
              {isOpenComments ? displayComments() : ''}
            </CardColumn>
          </CardRow> */}
          <CardRow transparent>
            <CardColumn>
              {taste.uuid_round_type &&
                sample.uuid_product_type &&
                (taste.uuid_round_type !==
                'b8082a00-e1d1-11ec-97a3-000000000000' ? (
                  <ScoreScale
                    key={1}
                    productType={sample.uuid_product_type}
                    roundType={taste.uuid_round_type}
                  />
                ) : (
                  <Accordion label={t('common_score_scale')} loading={false}>
                    <FreshnessFiveScoreScale
                      uuid_brand={sample.uuid_brand}
                      isAdminEdit={false}
                    />
                  </Accordion>
                ))}
            </CardColumn>
          </CardRow>
          <CardRow transparent>
            <CardColumn>
              <Fragment>
                <CardRow>
                  <CardColumn
                    padding="15px"
                    style={{ position: 'relative', cursor: 'pointer' }}
                    onClick={() => handleClickShowImage(isOpen)}
                  >
                    <Label
                      style={{ cursor: 'pointer' }}
                      label={t('tasting_beer_profile')}
                      bold
                    />
                    <span className={style.buttonShow}>
                      {isOpen ? <ArrowUp /> : <ArrowDown />}
                    </span>
                  </CardColumn>
                </CardRow>
                {isOpen ? (
                  <CardRow>
                    <CardColumn padding="0" style={{ position: 'relative' }}>
                      {displayImage()}
                    </CardColumn>
                  </CardRow>
                ) : (
                  ''
                )}
              </Fragment>
            </CardColumn>
          </CardRow>
        </div>
      )}
    </Fragment>
  );
};

export default DetailDiscussion;

