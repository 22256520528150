import editZITECStyle from './NewZITEC.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Label,
  Card,
  CardRow,
  DropdownField,
  DropdownMultiField,
  DatePickerField,
  TextField,
  UploadButton,
  Button,
  Modal,
  AutoComplete,
  CardDocument,
  CardColumn,
} from '../../../components';
import ReactTooltip from 'react-tooltip';
import { HelpCircle, Edit2 } from 'react-feather';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';
import RoundService from '../../services/rounds/round.service';
import { useUser } from '../../../contexts/User';
import moment from 'moment';
import UserService from '../../services/users/user.service';

const NewZITEC = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const appService = new AppService();
  const reportService = new ReportService();
  const roundService = new RoundService();
  const userService = new UserService();
  const _props = props.location.state
  const { user } = useUser();

  const isZhOrKr = JSON.parse(
    user.str_language === 'zh' || user.str_language === 'kr',
  );
  const [zitecDatas, setZitecDatas] = useState({
    uuid_zone: user.uuid_zone,
    dat_app_date: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    str_tasters: {
      str_name: user.str_name,
      uuid_user: user.uuid_user,
      str_email: user.str_email,
    },
    uuid_app_user: user.uuid_user,
    uuid_reminder_time: 'f70995d8-9cb2-11ef-b6d5-0017fa02c1e1',
  });
  const [timePointsOpts, setTimePointsOpts] = useState([]);
  const [timeZoneOps, setTimeZoneOps] = useState([]);
  const [ReminderTimerOpts, setReminderTimerOpts] = useState([])
  const [strExecDate, setStrExecDate] = useState('');
  const [testMethodOps, setTestMethodOps] = useState([]);
  const [liquidCategoryOps, setLiquidCategoryOps] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    getReminderTime();
    getTimePoints();
    getTestMethod();
    getLiquidCategory();
  }, [])

  useEffect(() => {
    getTimeZone();
  }, [zitecDatas.uuid_zone]);

  const getTimeZone = () => {
    if (zitecDatas.uuid_zone) {
      roundService.getTimeZone(zitecDatas.uuid_zone).then(res => {
        if (res) {
          setTimeZoneOps(res.results.length ? res.results : []);
        }
      })
    }
  }
  const getReminderTime = () => {
    roundService.getReminderTime().then(res => {
      if (res.type === 'success') {
        setReminderTimerOpts(res.results)
      }
    })
  }
  const handleSearchTasters = text => {
    return new Promise((resolve, reject) => {
      userService.findUser({ str_name: text }).then(
        res => {
          if (res.type === 'success' && res.results) {
            resolve(res.results);
          } else {
            window.alert(res.message);
            reject(res.message);
          }
        },
        error => {
          //console.log(error);
          reject(error);
        },
      );
    });
  };
  const handleSelectTaster = evt => {
    if (evt.target.value) {
      setEditUser(evt.target.value);
      // setZitecDatas({
      //   ...zitecDatas,
      //   str_tasters: evt.target.value,
      //   uuid_app_user: evt.target.value.uuid_user,
      // })
    }
  }
  const getTestMethod = () => {
    roundService.getTestMethod().then(res => {
      if (res.type === 'success') {
        setTestMethodOps(res.results ?? [])
      }
    })
  }
  const getLiquidCategory = () => {
    roundService.getLiquidCategory().then(res => {
      if (res.type === 'success') {
        setLiquidCategoryOps(res.results ?? [])
      }
    })
  }
  const getTimePoints = () => {
    roundService.getTimePoints().then(res => {
      if (res.type === 'success') {
        setTimePointsOpts(res.results ?? [])
      }
    })
  }
  const handleComputedExecutionDate = (dat_app_date, arr_time_points) => {
    if (dat_app_date && arr_time_points) {
      const ExecutionDate = [];
      for (let i = 0; i < arr_time_points.length; i++) {
        const dataTime = Number(arr_time_points[i].int_time_value) * 3600000 * 24;
        const newDate = new Date(dat_app_date).getTime() + dataTime;
        const momentDate = moment(new Date(newDate)).format('YYYY-MM-DD')
        ExecutionDate.push(momentDate);
      }
      setStrExecDate(ExecutionDate.join(', '));
    } else {
      setStrExecDate('');
    }
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setZitecDatas({
      ...zitecDatas,
      [name]: value,
    });
    if (name === 'arr_time_points' || name === 'dat_app_date') {
      handleComputedExecutionDate(
        name === 'dat_app_date' ? value : zitecDatas.dat_app_date,
        name === 'arr_time_points' ? value : zitecDatas.arr_time_points
      );
    }
  }
  const handleBack = () => {
    history.replace({ pathname: '/list_ZITEC' })
  }
  const handleSave = async () => {
    if (!zitecDatas.uuid_zone) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('common_zone')}` }),
      );
    }
    if (!zitecDatas.uuid_time_zone) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('common_time_zone')}` }),
      );
    }
    if (!zitecDatas.str_project_name) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('zitec_project_name')}` }),
      );
    }
    if (!zitecDatas.uuid_app_user) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('applicant')}` }),
      )
    }
    if (!zitecDatas.dat_app_date) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('applicant_date')}` }),
      )
    }
    if (!zitecDatas.uuid_liquid_category) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('liquid_category')}` }),
      )
    }
    if (!zitecDatas.uuid_test_method) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('test_method')}` }),
      )
    }
    if (!zitecDatas.str_conditions) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('conditions')}` }),
      )
    }
    if (!zitecDatas.arr_time_points) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('time_points')}` }),
      )
    }
    if (!zitecDatas.dat_email_invt_start_time) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('invitation_start_time')}` }),
      )
    }
    if (!zitecDatas.dat_email_invt_end_time) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('invitation_end_time')}` }),
      )
    }
    if (!zitecDatas.uuid_reminder_time) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('reminder_time')}` }),
      )
    }
    if (!zitecDatas.str_app_from || zitecDatas.str_app_from?.length === 0) {
      return window.alert(
        t('common_cannot_be_empty', { field: `${t('application_form')}` }),
      )
    }
    setSaveLoading(true);

    const data = {
      ...zitecDatas,
      str_exec_date: strExecDate,
      arr_time_points: zitecDatas.arr_time_points.map(o => o.uuid_time_points)
    }

    const res = await roundService.saveZITEC(data);
    if (res) {
      if (res.type === 'success') {
        const emailData = {
          str_zitec_id: res.results[0]['round_sp_in_up_zitec'],
          uuid_zitec: data.uuid_zitec,
          str_project_name: data.str_project_name,
          str_app_user_email: data.str_tasters.str_email,
          str_app_user: data.str_tasters.str_name,
          str_zone_name: _props.privilege?.arr_zones.find(o => o.uuid_zone === data.uuid_zone).str_name,
          str_exec_date: data.str_exec_date,
          str_conditions: data.str_conditions,
          str_time_points: data.arr_time_points.map(item => timePointsOpts.find(o => o.uuid_time_points === item).str_time_point).join(', '),
          dat_app_date: data.dat_app_date,
          str_test_method: testMethodOps.find(item => item.uuid_test_method === data.uuid_test_method).str_test_method,
          dat_email_invt_start_time: data.dat_email_invt_start_time,
          dat_email_invt_end_time: data.dat_email_invt_end_time,
          str_time_zone: timeZoneOps.find(item => item.uuid_time_zone === data.uuid_time_zone).str_time_zone_iana,
        }
        roundService.sentEmailZITEC(emailData)
        history.replace({ pathname: '/list_ZITEC' })
      }
    }
    setSaveLoading(false);
  }
  const handleRemove = () => {
    setZitecDatas({
      ...zitecDatas,
      str_app_from: '',
    })
  }
  const [Edit, setEdit] = useState(false);
  const [editUser, setEditUser] = useState('');
  const handleCheCkTaster = (user = {}) => {
    setEditUser(user);
    setEdit(true);
  }
  const saveEditUser = () => {
    setZitecDatas({
      ...zitecDatas,
      str_tasters: editUser,
      uuid_app_user: editUser.uuid_user,
    })
    setEdit(false);
  }
  return (
    <div className={editZITECStyle.pageContainer}>
      <Label
        label={
          t('new_zitec_session')
        }
        bold
        neutral
      />
      <Card bordered>
        <CardRow style={{ flexWrap: 'wrap' }}>
          <DropdownField
            margin="10px 10px 0px 0px"
            name="uuid_zone"
            label={t('common_zone')}
            options={_props.privilege.arr_zones ? _props.privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            value={zitecDatas.uuid_zone}
            onChange={handleChange}
            isRequired={true}
          />
          <DropdownField
            name="uuid_time_zone"
            options={
              timeZoneOps
            }
            valueField="uuid_time_zone"
            labelField="str_time_zone_name"
            label={`${t('common_time_zone')} `}
            margin="10px 10px 0px 0px"
            value={zitecDatas.uuid_time_zone}
            onChange={handleChange}
            hideRemoveButton
            isRequired={true}
          />
          <TextField
            name="str_project_name"
            label={t('zitec_project_name')}
            margin="10px 10px 0px 0px"
            isRequired={true}
            value={zitecDatas.str_project_name}
            onChange={handleChange}
          />
          {/* select */}
        </CardRow>
        <CardRow>
          <div className={editZITECStyle.applicant}>
            <TextField
              name="uuid_app_user"
              label={t('applicant')}
              margin="10px 10px 0px 0px"
              disabled
              value={zitecDatas.str_tasters?.str_name}
              isRequired={true}
            />
            <span
              className={editZITECStyle.editIcon}
              onClick={() => { handleCheCkTaster(zitecDatas.str_tasters) }}
            >
              <Edit2 color='#f49b00' />
            </span>
          </div>
          <DatePickerField
            name="dat_app_date"
            type={'datetime-local'}
            label={t('applicant_date')}
            margin="10px 10px 0px 0px"
            isRequired={true}
            value={zitecDatas.dat_app_date}
            onChange={handleChange}
          />
          <DropdownField
            margin="10px 10px 0px 0px"
            name="uuid_liquid_category"
            label={t('liquid_category')}
            value={zitecDatas.uuid_liquid_category}
            labelField="str_liquid_category"
            valueField="uuid_liquid_category"
            options={liquidCategoryOps}
            onChange={handleChange}
            isRequired={true}
          />
        </CardRow>
        <CardRow>

          <DropdownField
            margin="10px 10px 0px 0px"
            name="uuid_test_method"
            label={t('test_method')}
            value={zitecDatas.uuid_test_method}
            labelField="str_test_method"
            valueField="uuid_test_method"
            options={testMethodOps}
            onChange={handleChange}
            isRequired={true}
          />
          <TextField
            name="str_conditions"
            label={t('conditions')}
            margin="10px 10px 0px 0px"
            isRequired={true}
            value={zitecDatas.str_conditions}
            onChange={handleChange}
            maxLength={50}
          />
        </CardRow>
        <CardRow>
          <DropdownMultiField
            margin="10px 10px 0px 0px"
            name="arr_time_points"
            label={t('time_points')}
            labelField='str_time_point'
            valueField='uuid_time_points'
            isRequired={true}
            maxValueSelect={5}
            messageMaxValue={t('you_can_only_select_up_to_five')}
            bolOrder={false}
            value={zitecDatas.arr_time_points}
            options={timePointsOpts}
            onChange={handleChange}
          />
        </CardRow>
        <CardRow>
          <TextField
            name="str_exec_date"
            label={t('execution_by_time_points')}
            margin="10px 10px 0px 0px"
            value={strExecDate}
            disabled
          />
        </CardRow>
        <CardRow>
          <DatePickerField
            name="dat_email_invt_start_time"
            type={'time'}
            label={t('invitation_start_time')}
            margin="10px 10px 0px 0px"
            isRequired={true}
            onChange={handleChange}
            value={zitecDatas.dat_email_invt_start_time}
          />
          <DatePickerField
            name="dat_email_invt_end_time"
            type={'time'}
            label={t('invitation_end_time')}
            margin="10px 10px 0px 0px"
            isRequired={true}
            onChange={handleChange}
            value={zitecDatas.dat_email_invt_end_time}
          />
          <DropdownField
            margin="10px 10px 0px 0px"
            name="uuid_reminder_time"
            label={t('invitation_reminder')}
            options={
              ReminderTimerOpts
            }
            onChange={handleChange}
            value={zitecDatas.uuid_reminder_time}
            valueField="uuid_reminder_time"
            labelField="str_reminder_time"
            bolOrder={false}
            isRequired={true}
          />
        </CardRow>
        <CardColumn padding='10px 0'>
          <Label
            label={t('application_form')}
            required
          />
          {zitecDatas.str_app_from &&
            <CardDocument
              name="str_zip_documents"
              src={zitecDatas.str_app_from}
              onRemove={() => handleRemove()}
              margin="10px 0px"
              size={80}
              width="100px"
            />
          }
        </CardColumn>
        <CardRow flexWrap="wrap">
          <React.Fragment>
            <div className={editZITECStyle.uploadCard}>
              <UploadButton
                name="str_app_from"
                label={t('sample_upload_document')}
                onUpload={handleChange}
                allowExtension={['.jpeg', '.jpg', '.png', '.pdf', '.eml', '.xls', '.xlsx']}
                maxSize={5242880}
                disabled={
                  zitecDatas.str_app_from
                }
              />
              <HelpCircle
                data-tip={t(
                  'documents_upload_tips', {
                  field: '.jpeg, .jpg, .png, .pdf, .eml, .xls, .xlsx'
                }) + '(5mb).'
                }
                className={editZITECStyle.modelHelpCircle}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editZITECStyle.tooltip}
              />
            </div>
          </React.Fragment>
        </CardRow>
        <CardRow justifyContent="flex-end" flexWrap="wrap">
          <Button
            label={t('common_cancel')}
            onClick={() => {
              handleBack();
            }}
            neutral
            margin="10px 10px 0px 0px"
            disabled={saveLoading}
          />
          <Button
            label={t('save_and_send_email')}
            onClick={handleSave}
            confirm
            blue
            loading={saveLoading}
            // disabled={disabledSave || !props.sample.uuid_site}
            margin="10px 10px 0px 0px"
            style={{
              width: '200px',
              marginRight: 0,
            }}
          />
        </CardRow>
      </Card>
      <Modal
        show={Edit}
        className={editZITECStyle.modalContainer}
      >
        <AutoComplete
          useDebounce
          minimalToSearch={isZhOrKr ? 2 : 3}
          name="tasters"
          label={t('common_search_taster')}
          mainDivStyle={{ margin: '10px 10px 0px 0px' }}
          optionKeys={['str_abi_id', 'str_name', 'str_email']}
          onSearch={handleSearchTasters}
          onSelect={handleSelectTaster}
        />
        <TextField
          name="uuid_app_user"
          label={t('applicant')}
          margin="10px 10px 0px 0px"
          disabled
          value={editUser?.str_name}
        />
        <div className={editZITECStyle.modalButton}>
          <Button
            label={t('group_close_modal')}
            neutral
            onClick={() => {
              setEdit(false);
            }}
          />

          <Button
            margin="0px 10px"
            confirm
            label={t('confirm')}
            onClick={() => {
              saveEditUser();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
export default NewZITEC;
